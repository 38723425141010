<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Novo Agendamento</p>
        </header>
        <b-message v-for="(erro, index) in erros"
                   v-bind:key="index"
                   :title="$t('AGENDAMODAL.ERRO')"
                   type="is-danger"
                   :aria-close-label="$t('AGENDAMODAL.ERRO')">
            {{ erro }}
        </b-message>
        <section class="modal-card-body">
            <div class="columns">
                <div class="column">
                    <ValidationProvider rules="required"
                                        name="valor"
                                        v-slot="{ errors, valid }">
                        <b-field :label="$t('AGENDAMODAL.DATAHORA')" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                            <b-datetimepicker v-if="isInRole('agenda-incluir-encaixe') || !habilitarVisaoDiario" ref="data"
                                              rounded
                                              :placeholder="$t('AGENDAMODAL.SELECIONARHORA')"
                                              v-model="item.data"
                                              icon="calendar-today"
                                              :timepicker="{ enableSeconds: false, hourFormat: '24' }"
                                              horizontal-time-picker>
                            </b-datetimepicker>
                            <span v-else >{{ $moment(item.data).format("DD/MM/YYYY HH:mm") }}</span>
                        </b-field>
                    </ValidationProvider>
                </div>
            </div>
            <div class="columns">
                    <searchIdName :leitura="isAgendado" class="column" table="paciente" :label="$t('SISTEMA.PACIENTE')" :id.sync="idPaciente" @item="atualizaPaciente">
                        <slot>
                            <b-button icon-left="plus-box"
                                    tag="router-link"
                                    :to="{ name: 'paciente', query: { retorno: true }, query: { retornarPaciente:true} }"
                                    target="_blank"
                                    :title="$t('AGENDAMODAL.NOVOPACIENTE')"></b-button>
                        </slot>
                    </searchIdName>
                    <searchIdName class="column" :leitura="habilitarVisaoDiario" table="tipoagendamento" :label="$t('AGENDAMODAL.TIPOAGENDAMENTO')" :id.sync="idTipoAgendamento" @item="atualizaTipoAgendamento">
                        <slot>
                            <b-button icon-left="plus-box"
                                tag="router-link"
                                :to="{ name: 'tipoagendamento', query: { retorno: true },query: { retornarTipoAgendamento:true}}"
                                target="_blank"
                                :title="$t('AGENDAMODAL.NOVOSTATUS')"></b-button>
                        </slot>
                    </searchIdName>
                    <div class="column is-4">
                        <b>{{$t('AGENDAMODAL.FUNCIONARIORESPONSAVEL')}}</b>
                        <b-select v-model="item.funcionarioId">
                            <option :value="nenhum">{{$t('AGENDAMODAL.NENHUM')}}</option>
                            <template v-for="(item, index) in this.item.funcionariosAtivos">
                                <option v-if="item.funcionarioId == item.id" selected v-bind:key="index" :value="item.id">
                                    {{item.nome}}
                                </option>
                                <option v-else v-bind:key="index" :value="item.id">
                                    {{item.nome}}
                                </option>
                            </template>
                        </b-select>
                    </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field :label="$t('AGENDAMODAL.OBSERVACAO')">
                        <b-input type="textarea"
                                 v-model="item.observacao"
                                 @input="$emit('update:observacao', $event)">
                        </b-input>
                    </b-field>
                </div>
            </div>
        </section>

        <footer class="modal-card-foot">
            <button class="button is-pulled-right is-success"
                    @click="confirmar">
                {{$t('SISTEMA.CONFIRMAR')}}
            </button>

            <button class="button" type="button" @click="$parent.close()">Fechar</button>

        </footer>

    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'   
    import moment from "moment";
    import searchIdName from '@/components/searchIdName.vue'

    export default {
        props: {
            data: null,
            idPaciente: null,
            idTipoAgendamento: null,
            observacao: null,
            funcionarioId: null,
            id: null,
            habilitarVisaoDiario: null            
        },
        components: {
            searchIdName
        },
        computed: {
            ...mapState([
                'config'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        data() {
            return {
                item: {
                    observacao: null,
                    paciente: null,
                    tipoAgendamento: null,
                    data: null,
                    funcionarioId: null,
                    funcionariosAtivos: []
                },
                isAgendado: (this.id >0),
                erros: [],
                nenhum: null,
            };
        },
        watch: {
            data(newValue) {
                if (newValue) {
                    this.item.data = newValue;
                }
            },
            observacao(newValue) {
                if (newValue) {
                    this.item.observacao = newValue;
                }
            },
        },
        filters: {
            data: (valor) => {
                if (!valor) return null;

                return moment(valor).format();
            },
        },

        created() {

            document.addEventListener('visibilitychange', this.verificaRetornar, false);            
        },        
        mounted() {
            this.item.data = moment(this.data).toDate();
            this.item.observacao = this.observacao;
            this.item.funcionarioId = this.funcionarioId;
            if (!this.id) {
                if(this.isInRole('agenda-incluir-encaixe')) this.$refs.data.toggle();
                
            }

            this.$http.get('/api/search/FuncionariosAtivos?apenasCrmCadastrado=true')
            .then(res => res.data)
            .then(data => {
                if (data != null) {
                    this.item.funcionariosAtivos = data;
                    
                }
            });
        },
        methods: {
            verificaRetornar() {                   
                if(localStorage.retornarTipoAgendamento > 0){                    
                    this.idTipoAgendamento = localStorage.retornarTipoAgendamento;                    
                    localStorage.retornarTipoAgendamento = null;
                } 
                if(localStorage.retornarPaciente > 0){                    
                    this.idPaciente = localStorage.retornarPaciente;                    
                    localStorage.retornarPaciente = null;
                }                 
                                            
            },                               
            atualizaPaciente(dados) {
                this.item.paciente = dados;
                this.$emit('update:paciente', dados);
            },
            atualizaTipoAgendamento(dados) {
                this.item.tipoAgendamento = dados;
                this.$emit('update:tipoagendamento', dados);
            },
            confirmar() {
                this.erros = [];
                if (!this.item.paciente?.id && !this.item.paciente?.data?.id) {
                    this.erros.push(this.$t('AGENDAMODAL.MENSAGEMPACIENTE'));
                }

                if (!this.item.tipoAgendamento?.id && !this.item.tipoAgendamento?.data?.id) {
                    this.erros.push(this.$t('AGENDAMODAL.MENSAGEMTIPOAGENDAMENTO'));
                }

                if (!this.id && moment(this.item.data).isSameOrBefore(moment())) {
                    this.erros.push(this.$t('AGENDAMODAL.MENSAGEMDATAHORA'));
                }

                if (this.erros.length) return;

                const item = {
                    id: null,
                    paciente: this.item.paciente,
                    tipoAgendamento: this.item.tipoAgendamento,
                    observacao: this.item.observacao,
                    data: this.item.data,
                    funcionarioId: this.item.funcionarioId,
                    funcionariosAtivos: this.item.funcionariosAtivos
                };

                if(!item.paciente.id){
                    item.paciente = this.item.paciente.data;
                }

                if(!item.tipoAgendamento.id){
                    item.tipoAgendamento = this.item.tipoAgendamento.data;
                }

                if (this.id) {
                    item.id = this.id;
                }

                let url =('/api/atendimento/GuiaAgendaVerificaMesmoHorario?datahora=' + moment(item.data).format('YYYY-MM-DDTHH:mm:ss'))

                if(item.id && item.id > 0){
                    url +=  '&agendaId='+item.id;
                }

                if(item.tipoAgendamento && item.tipoAgendamento.id) {
                    url +=  '&tipoAgendamento=' + item.tipoAgendamento.id
                }
                
                if(item.funcionarioId > 0 && item.funcionarioId != null ) {
                    url +=  '&funcionarioId=' + item.funcionarioId
                }

                this.$http.get(url)
                    .then(res => {                        
                        if(!res.data){
                            this.$emit('novo', item)
                            this.$parent.close()
                        }
                        else{
                            this.$buefy.dialog.alert({
                                title: this.$t('AGENDAMODAL.RESPONSAVELOCUPADO'),
                                message: this.$t('AGENDAMODAL.RESPONSAVELOCUPADOMENSAGEM'),
                                type: 'is-warning',
                                hasIcon: true                                          
                            })
                        }
                    }).catch(err => {                                                
                        if (err.status == 404) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('AGENDA.ERROAOSALVAR'),
                                type: 'is-danger',
                                queue: false
                            })                            
                        }
                    });
            },
            confirmarInclusaoMesmoDiaHora(item){
                this.$buefy.dialog.confirm({
                    title: this.$t('AGENDAMODAL.HORARIOPREENCHIDO'),
                    message: this.$t('AGENDAMODAL.MENSAGEMHORARIOPREENCHIDO'),
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: "Cancelar",                                               
                    onConfirm: () => {
                        this.$emit('novo', item)
                        this.$parent.close()
                    }
                });
            }
        },
    };
</script>
