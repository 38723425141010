<template>
    <section>
        <div class="column">
            <titlebar :title-stack="titleStack"> </titlebar>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onSubmit)">
                    <div class="tile is-ancestor">
                        <div class="tile is-parent is-vertical is-12">
                            <article class="tile is-child box">
                                <div class="columns">
                                    <div class="column">
                                        <ValidationProvider rules="required"
                                                            name="nome"
                                                            v-slot="{ errors, valid }">
                                            <b-field :label="$t('GRUPOSEXAMECADASTRO.NOME')"
                                                     :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                     :message="errors">
                                                <b-input type="text"
                                                         v-model="model.nome"
                                                         maxlength="40"
                                                         :has-counter="true"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <buscaExame :label="$t('GRUPOSEXAMECADASTRO.EXAME')" :items.sync="grupoItens" />
                                <br />
                                <buscaConvenio :label="$t('SISTEMA.CONVENIO')" :items.sync="model.convenios" />
                            </article>
                        </div>
                    </div>
                    <nav class="level">
                        <div class="level-item">
                            <p class="buttons">
                                <b-button type="is-success"
                                          v-show="isInRole('exameGrupo-alterar')"
                                          :loading="salvandoDados"
                                          native-type="submit"
                                          icon-left="check-circle">
                                    {{$t('GRUPOSEXAMECADASTRO.SALVAR')}}
                                </b-button>
                                <b-button type="is-danger"
                                          native-type="button"
                                          icon-left="close-circle"
                                          @click="$router.back()">
                                    {{$t('GRUPOSEXAMECADASTRO.CANCELAR')}}
                                </b-button>
                            </p>
                        </div>
                    </nav>
                </form>
            </ValidationObserver>
        </div>
    </section>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import titlebar from "@/components/titlebar.vue";
    import buscaExame from "@/components/manutencao/buscaExame";
    import buscaConvenio from "@/components/manutencao/buscaConvenio";

    export default {
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    convenios: [],
                },
                grupoItens: [],
                salvandoDados: false,
            };
        },
        components: {
            titlebar,
            buscaExame,
            buscaConvenio
        },
        created() {
            if (this.$route.params.id) {
                this.$http
                    .get("/api/manutencao/exameGrupo?id=" + this.$route.params.id)
                    .then((res) => {
                        const grupoItens = !res.data?.grupoItens
                            ? []
                            : res.data.grupoItens.map((g) => {
                                return {
                                    apelido: g.exame?.apelido,
                                    materialNome: g.recipiente?.material?.nome,
                                    conservanteNome: g.recipiente?.conservante?.nome,
                                    id: `${g.exame?.id}_${g.recipiente?.id}`,
                                    recipienteId: g.recipiente?.id,
                                    nome: g.exame?.nome,
                                    show: true,
                                    changed: false
                                };
                            });
                        const convenios = res.data?.convenios?.map(c => {
                            return {
                                id: c.id,
                                nome: c.nome
                            }
                        })
                        res.data.convenios = convenios;
                        this.model = res.data;
                        this.grupoItens = grupoItens;
                    });
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('GRUPOSEXAMECADASTRO.MANUTENCAO'),
                    this.$t('GRUPOSEXAMECADASTRO.GRUPOEXAME'), 
                    this.model.id ?? "novo"
                    ];
            },
            ...mapState(["unidadeId", "config", "integradores"]),
            ...mapGetters(["isInRole"]),
        },
        watch: {},
        methods: {
            onSubmit() {
                const newItem = {
                    exameGrupo: Object.assign({}, this.model),
                    ids: this.grupoItens?.filter(i => i.id != null)?.map((gi) => gi.id),
                };

                newItem.exameGrupo.convenios = newItem.exameGrupo?.convenios?.filter(c => c.id != null);

                if (!this.$route.params.id) {
                    delete newItem.exameGrupo?.id;
                }
                delete newItem.exameGrupo?.grupoItens;

                this.$http
                    .post("/api/manutencao/exameGrupo", newItem)
                    .then(() => {
                        this.$router.back()
                    })
                    .catch((err) => {
                        console.error(err);
                        Object.keys(err.body.errors).forEach((x) => {
                            this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                        });
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('GRUPOSEXAMECADASTRO.ERROSALVAR') + err.body.title,
                            type: "is-danger",
                            queue: false,
                        });
                    });
            },
        },
    };
</script>
