<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <b-loading is-full-page="true" v-model="loading"></b-loading>
        <div class="column">
            <div class="columns is-multiline">
                <estudo v-for="(estudo, index) in model" :key="index" :estudo="estudo" :index="index" :selecionado="estudoSelecionado.includes(estudo)" v-on:selecionarEstudo="selecionarEstudo(estudo)" :mostraAccessionNumber="true"></estudo>
            </div>
        </div>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import estudo from '@/components/atendimento/estudo.vue'

    export default {
        data() {
            return {
                model: [],
                estudoSelecionado: [],
                loading: false
            }
        },
        computed: {
            titleStack() {
                return [
                    'Análise',
                    this.$route?.query?.semVinculo
                        ? 'Estudos sem vínculo'
                        : 'Estudos'
                ]
            },              
        },
        components: {
            titlebar,
            estudo
        },
        mounted() {
            const params = [
                this.$route?.query?.semVinculo
                    ? 'semVinculo=true'
                    : `unidadeId=${this.$route?.query?.unidadeId}`
            ].join('&')

            this.loading = true
            this.$http.get(`/api/analitico/estudos?${params}`)
                .then(({ data }) => {
                    this.model = data;
                    this.loading = false
                })
                .catch((error) => {
                    this.model = []
                    this.loading = false
                    throw error
                })            
        },
        methods: {
            selecionarEstudo(estudo) {
                if (this.estudoSelecionado == null) {
                    this.estudoSelecionado = [estudo]
                } else {
                    if (this.estudoSelecionado.includes(estudo)) {
                        this.estudoSelecionado = this.estudoSelecionado.filter(function(x) { return x != estudo })
                    } else {
                        this.estudoSelecionado.push(estudo)
                    }
                }
            },            
        }
    }
</script>