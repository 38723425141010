<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>

        <div class='box' v-if="model != null && model.guia != null">
            <div class="level">
                <div class="level-item">

                    <div>
                        <p class="heading">Guia</p>
                        <p class="title">

                            <router-link :to="{ name: 'guia', params: { id: model.guia.id }}">
                                {{ model.guia.id}}
                            </router-link>                      

                        </p>
                    </div>

                </div>              

                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Paciente</p>
                        <p class="title">{{model.guia.pacienteNome}}</p>
                    </div>
                </div>  

                <div class="level-item has-text-centered" v-if="model.guia.guiaConferida">
                    <div>
                        <p class="heading"></p>
                        <p class="title">Guia Conferida</p>
                    </div>
                </div>                                  

                <div class="level-item has-text-centered" >
                    <div>
                        <p class="heading"> Data de retorno:</p>
                        <p class="title">{{ $moment(model.guia.dataDeRetorno).format("DD/MM/YYYY HH:mm") }}</p>
                    </div>
                </div>                                                  
            
                <div class="level-item">
                    <router-link :to="{ name: 'clonarguia', params: { clonarGuiaId: model.guia.id }}" class="button is-primary">
                        Nova guia
                    </router-link>
                </div>
            </div>
        </div>

        <div class="box" v-if="model != null && model.guia != null">
            <div class="columns">
                <div class="column">
                    <div class="panel">
                        <div class="panel-heading background-2">
                            <h3 class="panel-title">Etiqueta</h3>
                        </div>
                        <div class="panel-block">
                            <b-button type="is-default" title="Imprimir etiqueta" @click="imprimirEtiquetaAmostraGuia()" :loading="loadingImpressaoEtiqueta" expanded>
                                Imprimir etiqueta
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="panel">
                        <div class="panel-heading background-2">
                            <h3 class="panel-title">Comprovante</h3>
                        </div>
                        <div class="panel-block">
                            <b-field label="Modelo">
                                <b-select v-model="model.comprovanteEscolhido">
                                    <option v-for="(modelo, index) in model.listaModelos" :key="index"
                                            :value="modelo">
                                        {{ modelo.nome }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="panel-block">
                            <b-button type="is-default" 
                                      expanded
                                      :loading="loadingImpressaoComprovante"
                                      @click="imprimirComprovanteGuia(model.guia.id)">
                                Imprimir comprovante
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="column" v-if="config.workflowBancada">
                    <div class="panel">
                        <div class="panel-heading background-2">
                            <h3 class="panel-title">Bancada</h3>
                        </div>
                        <div class="panel-block">
                            <router-link :to="{ name: 'emissao-bancada', query: { guiaId: model.guia.id } }" class="button is-default is-fullwidth">
                                Emitir bancada
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box" v-if="model != null && model.setoresIntegracao.length > 0">
            <div class="columns is-multiline">
                <div class="column is-12" v-for="(setor, j) in model.setoresIntegracao" v-bind:key="j">
                    <div class="panel">
                        <div class="panel-heading background-2">
                            <h3 class="panel-title">
                                <div class="level">
                                    <div class="level-right">
                                        {{setor.nome}}
                                    </div>
                                    <div class="level-left">
                                        <b-checkbox v-model="setor.selecionado" 
                                            @click.prevent.native="selecionarTodosSetor(setor)"></b-checkbox>
                                    </div>
                                </div>
                            </h3>
                        </div>
                        <b-message v-if="model.examesNaoEnviados.filter(exame => exame.exameSetorId == setor.id).length == 0">
                            Não existem exames pendentes de envio para este setor.
                        </b-message>
                        <div v-else class="panel-block" v-for="(exame, k) in model.examesNaoEnviados.filter(exame => exame.exameSetorId == setor.id)" v-bind:key="k">
                            <div v-if="exame.exameSetorId == setor.id" class="tile is-ancestor">
                                <div class="tile is-vertical is-11">
                                    <div class="tile">
                                        <div class="tile is-parent is-vertical">
                                            <div class="tile is-child">
                                                <b-tag>{{exame.item}}</b-tag>
                                                <b>{{exame.exameApelido}}</b>
                                                <b-checkbox v-model="exame.selecionado" class="is-hidden-tablet is-pulled-right"></b-checkbox>
                                            </div>
                                        </div>
                                        <div class="tile is-parent is-vertical is-4">
                                            <div class="tile is-child">
                                                <span>{{exame.exameNome}}</span>
                                            </div>
                                        </div>
                                        <div class="tile is-parent is-vertical is-5">
                                            <div class="tile is-child">
                                                <b-select placeholder="Selecione o Integrador" class="is-fullwidth" v-model="exame.integradorId">
                                                    <option v-for="(integ, index) in setor.integradores" v-bind:key="index" :value="integ.id">{{integ.nome}}</option>
                                                </b-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tile is-parent is-hidden-mobile is-1">
                                    <div class="tile is-child">
                                        <b-checkbox v-model="exame.selecionado"></b-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel-block" v-if="model.examesNaoEnviados.filter(exame => exame.exameSetorId == setor.id).length > 0">
                            <b-button type="is-success" outlined expanded
                                      native-type="button"
                                      :loading="salvandoDados"
                                      @click="enviarPedidosIntegracao(setor.id)">
                                Enviar
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import modalComprovanteVariavel from '@/components/atendimento/modalComprovanteVariavel.vue'
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
                model: {
                    comprovanteEscolhido: null,
                    examesNaoEnviados: [],
                    setoresIntegracao: []
                },
                id: this.$route.params.id,
                salvandoDados: false,
                opcoesBancada: null,
                loadingImpressaoEtiqueta: false,
                loadingImpressaoComprovante: false
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    'Atendimento',
                    'Workflow',
                    this.id
                ]
            },
            ...mapState([
                'config',
            ])
        },
        mounted() {
            this.salvandoDados = true
            this.$http.get(`/api/atendimento/Workflow?id=${this.$route.params.id}`)
                .then(({ data }) => {
                    this.model = data;

                    if (localStorage.modeloComprovanteNome == null) {
                        this.model.comprovanteEscolhido = this.model.listaModelos[0];
                    }
                    else {
                        this.model.comprovanteEscolhido = this.model.listaModelos.find(x => x.nome == localStorage.modeloComprovanteNome)
                    }
                })
                .catch((error) => {
                    this.model = null
                    throw error
                })
                .finally(() => {
                    this.salvandoDados = false
                })
        },
        methods: {
            selecionarTodosSetor(setor){
                setor.selecionado = !setor.selecionado;

                this.model.examesNaoEnviados.filter(exame => exame.exameSetorId == setor.id).forEach(exame => {
                    exame.selecionado = setor.selecionado;
                });

                this.$forceUpdate();
            },
            imprimirEtiquetaAmostraGuia() {
                this.loadingImpressaoEtiqueta = true
                this.$notificacaoHub.invoke("EtiquetaAmostraGuia",
                    [this.model.guia.id],
                    localStorage.etiquetaAtual)
                    .then(() => {
                        this.loadingImpressaoEtiqueta = false
                    })
                    .catch(e => {
                        this.loadingImpressaoEtiqueta = false
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: 'Erro na impressão de etiquetas: ' + e.toString(),
                            type: 'is-danger',
                            position: 'is-top-right',
                            queue: false
                        })
                    })
            },
            imprimirComprovanteGuia(id) {
                localStorage.modeloComprovanteNome = this.model.comprovanteEscolhido.nome

                if (this.model.comprovanteEscolhido.variaveis.length > 0) {
                    this.$buefy.modal.open({
                        parent: this,
                        component: modalComprovanteVariavel,
                        props: {
                            guia: this.model.guia,
                            comprovante: this.model.comprovanteEscolhido
                        },
                        hasModalCard: true,
                        trapFocus: true,
                        fullScreen: false
                    })
                }
                else {

                    this.loadingImpressaoComprovante = true
                    if (this.model.comprovanteEscolhido.rawPrinting) {
                        this.$notificacaoHub.invoke("ComprovanteGuia",
                            this.model.guia.id,
                            this.model.comprovanteEscolhido.nome, [])
                            .then(() => {
                                this.loadingImpressaoComprovante = false
                            })
                            .catch(e => {
                                this.loadingImpressaoComprovante = false
                                this.$buefy.snackbar.open({
                                    duration: 2000,
                                    message: 'Erro na impressão de comprovante: ' + e.toString(),
                                    type: 'is-danger',
                                    position: 'is-top-right',
                                    queue: false
                                })
                            })
                    } else {
                        window.open('/Guia/Comprovante?id=' + id + '&modelo=' + this.model.comprovanteEscolhido.nome);
                        this.loadingImpressaoComprovante = false
                    }
                }

            },
            enviarPedidosIntegracao(setorId) {
                let formData = new FormData()

                let index = 0;

                const setorIntegracao = this.model.setoresIntegracao.filter(setor => setor.id == setorId)[0];

                //separa os envios por integrador
                setorIntegracao.integradores.forEach(integ => {
                    formData = new FormData();
                    index = 0;

                    this.model.examesNaoEnviados.forEach(exame => {
                        if(integ.id == exame.integradorId){
                            if (exame.selecionado && exame.exameSetorId == setorId) {
                                formData.append("pedidosWorkflow.enviarPedidosWorkflow[" + index + "].GuiaExameId.GuiaId", this.id);
                                formData.append("pedidosWorkflow.enviarPedidosWorkflow[" + index + "].Item", exame.item);
                                index++;
                            }
                        }
                    });

                    //envia apenas integradores que tem pelo menos 1 exame selecionado
                    if(index != 0)
                    {
                        formData.append("pedidosWorkflow.integradorId", integ.id);

                        this.salvandoDados = true
                        this.$http.post('/api/atendimento/EnviarPedidosWorkflow', formData)
                            .then(res => res.data)
                            .then(data => {
                                this.$buefy.toast.open({
                                    duration: 3000,
                                    message: 'Exames enviados para o integrador ' + integ.nome + '.',
                                    type: 'is-success'
                                })
                            })
                            .catch((error) => {
                                this.lista = []
                                throw error
                            })
                            .finally(() => {
                                this.salvandoDados = false
                            })
                    }
                });
            }
        }
    }
</script>