<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALCANCELANDOEXAME.CANCELAREXAME')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('MODALCANCELANDOEXAME.ATENCAO')}}</h5>
                <p>{{$t('MODALCANCELANDOEXAME.AVISOCANCELAMENTO')}}</p>
            </b-notification>
            <b-notification v-if="erroCancelamentoExame"
                            type="is-danger">
                {{ erroCancelamentoExame }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('MODALCANCELANDOEXAME.EXAMES')">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-tag type="is-info" v-for="(exame,index) in selecionado" :key="index">{{ exame.exameApelido }}</b-tag>
                </p>
                <p class="panel-block">
                    <b-field :label="$t('MODALCANCELANDOEXAME.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-input v-model="procurarMotivosCancelamentoExame"
                             icon="magnify"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="procurarMotivosCancelamentoExame = ''"></b-input>
                </div>
                <div class="panel-block">
                    <b-select multiple
                              native-size="10"
                              expanded
                              v-model="motivoCancelamentoExameSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosCancelamentoExame" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                      @click="confirmarCancelamentoExame">{{$t('MODALCANCELANDOEXAME.CONFIRMARCANCELAMENTO')}}</b-button>
            <b-button @click="$emit('close')">{{$t('MODALCANCELANDOEXAME.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            guia: Object,
            selecionado: Array
        },
        data() {
            return {
                procurarMotivosCancelamentoExame: '',
                listaMotivosCancelamentoExame: [],
                motivoCancelamentoExameSelecionado: [],
                erroCancelamentoExame: null,
            }
        },
        computed: {
            filteredMotivosCancelamentoExame() {
                return this.procurarMotivosCancelamentoExame
                    ? this.listaMotivosCancelamentoExame.filter(x=>x.descricaoInterno!=null)
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosCancelamentoExame.toUpperCase()) > -1)
                    : this.listaMotivosCancelamentoExame
            },
        },
        mounted() {
            this.loadListaMotivosCancelamentoExame()
        },
        methods: {
            loadListaMotivosCancelamentoExame() {
                this.$http.get('/api/search/MotivosCancelamentoExame')
                    .then(m => {
                        this.listaMotivosCancelamentoExame = m.data
                    })
            },
            confirmarCancelamentoExame() {
                this.$http.post('/api/atendimento/cancelarExame', {
                    id: this.guia.id,
                    item: this.selecionado.map(x => x.item),
                    motivoIds: this.motivoCancelamentoExameSelecionado
                })
                    .then((res) => {
                        if (res.body.erro) {
                            this.erroCancelamentoExame = res.body.erro
                        } else {
                            this.$emit('loadGuia')
                            this.$emit('close')
                        }
                    })
            },

        }
    }
</script>