<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <b-table :data="lista"
                 :loading="loading"
                 striped
                 hoverable
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"
                 @sort="onSort"
                :checked-rows.sync="checkedRows"
                checkable
                :checkbox-position="checkboxPosition">

            <template @click="abrirCadastro(props.row.id)">
                <b-table-column  v-slot="props" field="Documento" label="Documento" sortable>
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Fornecedor" label="Fornecedor" sortable>
                    {{ props.row.fornecedor.nome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Nota Fiscal" label="Nota Fiscal" sortable>
                    {{ props.row.notaFiscal }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Conta Corrente" label="Conta Corrente" sortable>
                    {{ props.row.contaCorrente.nome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Emissão" label="Emissão" sortable>
                    {{ $moment(props.row.data).format("DD/MM/YYYY") }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Vencimento" label="Vencimento" sortable>
                    {{ $moment(props.row.dataDeVencimento).format("DD/MM/YYYY") }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Observações" label="Observações">
                    {{ props.row.observacoes }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Valor" label="Valor" sortable>
                    {{ formatPrice(props.row.valor) }}
                </b-table-column>
            </template>
        </b-table>
        <div class="columns">   
            <div class="column is-12">                 
                <article class="tile is-child box">           
                    <div class="columns">  
                        <div class="column is-1">
                            <b-field label="Total:">
                                <b-input type="text" v-model="total" readonly></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Espécie">
                                <b-select placeholder="Selecione a Espécie" v-model="especieDePagamento">
                                    <option value="1">Dinheiro</option>
                                    <option value="2">CartaoDeCredito</option>
                                    <option value="3">CartaoDeDebito</option>
                                    <option value="4">Cheque</option>
                                    <option value="5">TransferenciaBancaria</option>
                                    <option value="6">Boleto</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>     
                </article>
            </div>     
        </div>  
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                                :loading="salvandoDados"
                                native-type="button"
                                icon-left="check-circle"
                                @click="baixarParcelas();">
                        Baixar
                    </b-button>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                        Cancelar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>


<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                lista: [],
                ordenarPor: 'documento',
                ordenar: 'desc',
                defaultOrdenar: 'desc',
                loading: false,
                checkedRows: [],
                checkboxPosition: 'left',
                total: null,
                especieDePagamento: null,
                salvandoDados: false
            }
        },
        mounted() {
            this.loadAsyncData()
        },
        methods: {
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
            },
            loadAsyncData() {
                this.loading = true

                this.$http.get(`/api/financeiro/baixapagamentos?dataInicial=${this.$moment(this.$route.params.dataInicial).format()}&dataFinal=${this.$moment(this.$route.params.dataFinal).format()}`)
                    .then(({ data }) => {
                        console.log(data);
                        this.lista = data.lista;

                        for (let i = 0; i < this.lista.length; i++) {
                            this.total += this.lista[i].valor;
                        }
                        this.total = this.formatPrice(this.total);

                        this.loading = false
                    })
                    .catch((error) => {
                        this.lista = []
                        this.loading = false
                        throw error
                    })
            },
            formatPrice(value) {
                const val = (value/1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            baixarParcelas(){
                console.log("baixarParcelas");

                const baixapagamentosparcelas = {
                    documentos: this.checkedRows, 
                    especieDePagamento: this.especieDePagamento
                };

                this.salvandoDados = true;
                this.$http.post(`/api/financeiro/baixapagamentosparcelas`, baixapagamentosparcelas)
                    .then(() => {
                        this.salvandoDados = false;
                        this.confirmarBaixaParcelas();
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });  
            },
            confirmarBaixaParcelas(){
                this.$buefy.dialog.alert('Baixa de Pagamentos realizada com sucesso!')
                this.$router.go(0);
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    'Financeiro',
                    'Contas a Pagar',
                    'Baixa de Pagamentos'
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        }
    }
</script>