import { MutationTree } from 'vuex'
import { SenhaState } from './senhaState';

const state: SenhaState = {
	chamando: undefined,
	lista: [],
	ultimas: [],
	atendendo: undefined 
}

const mutations: MutationTree<SenhaState> = {
	CHAMAR(state, senha) {

		if (senha.tipoId == 1)
			senha.buttonType = 'is-primary'
		else if (senha.tipoId == 3)
			senha.buttonType = 'is-link'
		else if (senha.tipoId == 4)
			senha.buttonType = 'is-warning'
		else if (senha.tipoId == 5)
			senha.buttonType = 'is-success'
		else if (senha.tipoId == 6)
			senha.buttonType = 'is-danger'

		state.chamando = senha
		state.ultimas = state.ultimas.filter(x => x.id != senha.id)
		state.ultimas.unshift(senha)

		if (state.ultimas.length > 12) {
			state.ultimas.pop()
        }
	},
	APAGAR(state, id) {
		console.log(state.chamando)
		if (state.chamando != undefined && state.chamando.id == id) {
			state.chamando = undefined
        }
		state.lista = state.lista.filter(x => x.id != id)
		state.ultimas = state.ultimas.filter(x => x.id != id)
	},
	SET_LISTA(state, lista) {
		state.lista = lista
	},
	SET_ATENDENDO(state, senha) {
		state.atendendo = senha
    }
} 

export default { 
    namespaced: true,
    state,
    mutations 
}