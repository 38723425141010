<template>
    <section>
        <titlebar :title-stack="titleStack">

        </titlebar>
        <div class="box" ref="lista">

        

                <div class="columns is-flex">
                    <div class="column">
                        <b-button size="is-small" icon-left="magnify" @click="procurarGuia()">{{$t('CONFIRMARINTERFACE.BUSCARGUIA')}}</b-button>
                    </div>                    
                    <div class="column">
                        <b-button class="is-pulled-right" size="is-small" :icon-left="todosSelecionados ? 'close-circle-multiple' : 'check-all'" @click="selecionarTodos">{{ todosSelecionados ? $t('CONFIRMARINTERFACE.DESMARCAR') : $t('CONFIRMARINTERFACE.SELECIONAR') }}</b-button>
                    </div>

                </div>            
            <div v-if="isLoading">
                <div class="columns">
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                    <div class="column">
                        <b-skeleton animated active></b-skeleton>
                    </div>
                </div>
            </div>
            <div v-for="(item, index) in model.lista" v-bind:key="index" class="panel">
                <div class="panel-heading" :id="'guia_' + item.lisOrderID">
                    <div class="level">
                        <div class="level-left">
                          
                            <div class="level-item">
                                <div class="field has-addons">
                                 
                                    <p class="control">
       
                                        <b-button type="is-link" size="is-small" tag="router-link" 
                                                :to="{ name: 'guia', params: { id: item.lisOrderID }}" 
                                                icon-left="clipboard-plus-outline" :title="$t('CONFIRMARINTERFACE.GUIA')">
                                                {{ item.lisOrderID }}
                                        </b-button>                                        
                                    </p>

                                </div>
                            </div>

                            <div class="level-item">
                                <p class="control">
                                    <b-button type="is-link" size="is-small" tag="router-link" 
                                              :to="{ name: 'guia', params: { id: item.lisOrderID }}" 
                                              icon-left="flask-outline" :title="$t('CONFIRMARINTERFACE.AMOSTRA')">
                                            {{ item.sampleID }}
                                    </b-button>
                            
                                </p>
                            </div>    

                            <div v-if="item.guiaLocal" class="level-item">
                                {{ item.guiaLocal }}
                            </div>                                                 
                            
                            <div class="level-item">
                                {{ item.patientInfo }}
                            </div>                              

                        </div>
                        <div class="level-right">

                            <div class="level-item">        
                                <p class="control">
                                    <label class="b-checkbox checkbox is-medium is-marginless">
                                        <b-checkbox size="is-medium" @change.native="confirmGuiaChange(item.lisOrderID)"></b-checkbox>
                                    </label>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="item.medicamentos" class="panel-block">
                    <small><b>{{$t('CONFIRMARINTERFACE.MEDICAMENTOS')}}</b> {{ item.medicamentos }}</small>
                </div>
                <div v-for="(result, indexResult) in item.results" v-bind:key="indexResult">
                    <div :class="'panel-block is-block confirmacao-data'" 
                        v-if="config.ordenarConfirmacaoPorData && 
                        (indexResult == 0 || 
                            item.results[indexResult].observationDatetime != item.results[indexResult - 1].observationDatetime)">
                        <nav class="level">
                            <div class="level-left">
                                <div class="level-item">
                                    <h3 class="label">
                                        {{item.results[indexResult].observationDatetime | moment("DD/MM/YYYY HH:mm:ss:SSS")}}
                                    </h3>
                                </div>
                            </div>
                            <div class="level-right">
                                <div class="level-item">
                                    <b-button size="is-small" type="is-primary" icon-left="refresh" :loading="deletando" @click="refreshTestTime(item.results[indexResult].observationDatetime , item)"></b-button>   
                                    <b-button size="is-small" type="is-danger" :loading="deletando" icon-left="delete" @click="confirmDeleteTime(item.results[indexResult].observationDatetime , item)"></b-button>                                                                                            
                                    <b-checkbox size="is-medium" @change.native="confirmTimeChange(item.results[indexResult].observationDatetime , item)"></b-checkbox>   
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div class="columns panel-list-item">
                        <div class="column">
                            <b-icon
                                v-if="mediaUltimosResultados(item.patientId, result.lisTestCode, result.lisResultCode) != null && 
                                loadLastResults(item.patientId, result.lisTestCode, result.lisResultCode)[0].deltaCheck && 
                                isFloat(result.value) &&
                                ((result.value < (mediaUltimosResultados(item.patientId, result.lisTestCode, result.lisResultCode) * (1 - (loadLastResults(item.patientId, result.lisTestCode, result.lisResultCode)[0].deltaCheck / 100)))) || 
                                (result.value > (mediaUltimosResultados(item.patientId, result.lisTestCode, result.lisResultCode) * (1 + (loadLastResults(item.patientId, result.lisTestCode, result.lisResultCode)[0].deltaCheck / 100)))))"
                                type="is-danger" icon="alert-rhombus" size="is-medium" 
                                :title="'Média = ' + mediaUltimosResultados(item.patientId, result.lisTestCode, result.lisResultCode)  + 
                                ' - Delta = ' + loadLastResults(item.patientId, result.lisTestCode, result.lisResultCode)[0].deltaCheck + ''"></b-icon>
                                
                            <span class="tag">{{ result.lisTestID }}</span>
                            <b>{{ result.lisTestName }}</b>
                        </div>
                        <div class="column">
                            <p>
                                {{ result.resultName }} {{ result.resultCode }}
                                &#8660;
                                {{ result.lisResultName }}
                            </p>
                            <p v-for="(comment, indexComment) in result.comments" v-bind:key="indexComment" class="has-background-light">
                                <span>{{ comment.commentText }} </span>
                                <small>({{ comment.commentSource }}/{{ comment.commentType }})</small>
                            </p>
                        </div>                       
                        <div class="column has-text-right">
                            <h4 class="is-size-4">
                                <b-icon v-if="(result.ValorCriticoMinimo != null && result.value <= result.ValorCriticoMinimo) ||
                                     (result.valorCriticoMaximo != null && result.valorCriticoMaximo >= result.value)"
                                    icon="alert-rhombus"
                                    size="is-medium"
                                    class="is-pulled-left"
                                    :title="$t('CONFIRMARINTERFACE.VALORCRITICO')"
                                    type="is-danger"></b-icon>
                                <small v-show="result.referencesRange" class="tag is-warning">{{ result.referencesRange }}</small>
                                <span v-show="result.interpretationCode" class="tag is-danger">{{ result.interpretationCode }}</span>                                
                              
                                <span :class="verificaValorReferencia(result)"  > {{ result.value }}  </span>
                                <span :class="verificaValorReferencia(result)"  >{{ result.units }} </span>                                                                                                                                      
                                                     
                            </h4>
                        </div>
                        <div class="column has-text-right">
                              <span class="is-size-4"> <p  class="references"><span  v-html="result.valorReferenciaTexto"></span></p></span>
                        </div>                         
                        <div class="column is-2 has-text-right">
                            <b-button size="is-small" type="is-link" icon-left="pencil" @click="editResult(result)"></b-button>                                                                
                            <b-button size="is-small" type="is-primary" :loading="deletando" icon-left="refresh" @click="refreshTest(result)"></b-button>   
                            <b-button size="is-small" type="is-black" icon-left="cancel" @click="confirmarBloqueioExame(result,item.lisOrderID)"></b-button>                                                                                            
                            <b-button size="is-small" type="is-danger" :loading="deletando" icon-left="delete" @click="confirmDelete(result, item)"></b-button>                                                                                            
                            <b-checkbox size="is-medium" @change.native="result.confirm = !result.confirm" :value="result.confirm"></b-checkbox>                                
                        </div>
                    </div>
                    <div class="whitebox lastresults"  v-if="loadLastResults(item.patientId, result.lisTestCode, result.lisResultCode)">
                        <div>
                            <small><b>{{$t('CONFIRMARINTERFACE.RESULTADOANTERIOR')}}</b></small>
                            <div class="is-pulled-right">
                                <div class="field">
                                    <b-switch size="is-small" v-model="ultimosResultadosGrafico">{{$t('CONFIRMARINTERFACE.GRAFICO')}}</b-switch>
                                </div>
                            </div>
                        </div>
                        <div v-if="!ultimosResultadosGrafico">
                            <div class="columns is-multiline">
                                <div class="column is-3" v-for="(ur, indexUr) in loadLastResults(item.patientId, result.lisTestCode, result.lisResultCode)" :key="indexUr">
                                    <p class="tag is-info">{{ $moment(ur.dataDeSolicitacao).format("DD/MM/YYYY HH:mm") }}</p>
                                    <p>{{ ur.titulo }}: <b>{{ ur.valorFormatado }}</b></p>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div>
                                <div class="card">
                                    <ultimos-resultados :chart-data="loadLastResults(item.patientId, result.lisTestCode, result.lisResultCode)" height=100></ultimos-resultados>
                                </div>
                            </div>
                        </div>
                    </div>     
                </div>
            </div>
            <b-modal :active.sync="exibirModal" has-modal-card trap-focus aria-role="dialog" aria-modal>
                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">{{$t('CONFIRMARINTERFACE.EDITARRESULTADO')}}</p>
                    </header>
                    <section class="modal-card-body" v-if="resultEdit!=null">
                        <div class="media">
                            <div class="media-content" >
                                <p>{{ resultEdit.resultCode }}  &#8660; {{ resultEdit.lisResultName }}</p> 
                                <b-input type="text" v-model="resultEdit.value" @keyup.native.enter="verificarAbreviaturas(resultEdit)"></b-input>
                                
                            </div>
                        </div>
                    </section>
                    <footer class="modal-card-foot">                          
                        <b-button  @click="exibirModal = false; resultEdit.value = resultEdit.originalValue;">{{$t('CONFIRMARINTERFACE.CANCELAR')}}</b-button>
                        <b-button  class="is-primary" @click="saveResult(resultEdit)"  >{{$t('CONFIRMARINTERFACE.SALVAR')}}</b-button>
                    </footer>
                </div>
            </b-modal>                
            <b-pagination v-if="model.lista != null && model.lista.length > 0"
                :total="total"
                :current.sync="current"
                :range-before="rangeBefore"
                :range-after="rangeAfter"
                :per-page="perPage"
                :icon-prev="prevIcon"
                :icon-next="nextIcon"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page">
            </b-pagination>
        </div>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                              :loading="salvandoDados"
                              native-type="submit"
                              icon-left="check-circle"
                              @click="submit()">
                        {{$t('CONFIRMARINTERFACE.SALVAR')}}
                    </b-button>
                    <b-button type="is-danger"
                              native-type="button"
                              icon-left="close-circle"
                              @click="$router.back()">
                        {{$t('CONFIRMARINTERFACE.CANCELAR')}}
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>

<style scoped>
.lastresults {    
    border: 1px solid #dbdbdb; 
    padding: 10px;
}

.references {
    line-height:normal; 
    font-size:12px;
}
</style>

<script>
    import titlebar from '@/components/titlebar.vue'
    import UltimosResultados from '@/components/Charts/UltimosResultados'
    import modalBloqueioExame from '@/components/atendimento/modalBloqueioExame'
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
                model: {},
                ultimosResultados: [],
                ultimosResultadosGrafico: false,
                todosSelecionados: false,
                isLoading: true,
                salvandoDados: false,
                deletando: false,
                total: 1,
                current: 1,
                perPage: 20,
                rangeBefore: 3,
                rangeAfter: 1,
                prevIcon: 'chevron-left',
                nextIcon: 'chevron-right',
                exibirModal: false,
                resultEdit : null,
            }
        },
        components: {
            titlebar,
            UltimosResultados
        },
        watch: {
            current: function(val){
                this.model = {};
                this.isLoading = true;
                this.carregarLista();                
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('CONFIRMARINTERFACE.INTERFACE'),
                    this.$t('CONFIRMARINTERFACE.CONFIRMACAO'),
                    this.$route.params.id
                ]
            },
            loadLastResults() {
                return (patientid, listestcode, lisresultcode) => {
                    const x = this.ultimosResultados
                        .filter(x => x.params[0] == `pacienteId=${patientid}` && x.params[1] === `exameId=${listestcode}`)
                        .map(w => w.data.ultimosResultados
                            .filter(z => z.campoId == lisresultcode))

                    return x[0]
                }
            },
            mediaUltimosResultados(){
                return (patientid, listestcode, lisresultcode) => {
                    if ((this.loadLastResults(patientid, listestcode, lisresultcode) == null) || (this.loadLastResults(patientid, listestcode, lisresultcode).length == 0))
                        return null;

                    let qtd = 0.0;
                    let soma = 0.0;

                    this.loadLastResults(patientid, listestcode, lisresultcode).filter(item => item.campoId == lisresultcode && this.isFloat(item.valor)).forEach(campo => {
                                                
                        soma += parseFloat(campo.valor);
                        qtd++;                        
                    });
                    
                    return soma / qtd;
                }                
            },
            ...mapState([
                'config',
            ])
        },
        mounted() {
            this.carregarLista();
        },
        methods: {

            removerItemConfirmado(itemRemovido){

                this.model.lista.forEach(x => {   
                    if(x.lisOrderID == itemRemovido.guiaId ){
                        x.results = x.results.filter((i) => i.resultId !== itemRemovido.resultId);
                    }                 
                })

                console.log(JSON.stringify(itemRemovido));
            },
            confirmarBloqueioExame(result,lisOrderID) {

                this.$buefy.modal.open({
                    parent: this,
                    component: modalBloqueioExame,
                    props: {
                        guia: {id: lisOrderID},
                        result: result,
                        selecionado:  new Array( {exameApelido:result.lisTestName}),                        
                    },
                    events: {
                        removerItemConfirmado: value =>{
                            this.removerItemConfirmado(value);
                        }
                    },                    
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: false
                })
                            
            },            
            verificaValorReferencia(result){
                                                
                return result.foraValorReferencia ? 'has-text-danger' : '';
            
            },
            isFloat(n){
                return Number(n) == n;
            },
            carregarLista() {
                this.$http.get('/api/interface/confirm?id=' + this.$route.params.id + '&page=' + this.current)
                    .then(res => {
                        this.model = res.data
                        this.total = this.perPage * res.data.pageCount
                        this.isLoading = false
                        this.loadUltimosResultados()
                    })
            },
            loadUltimosResultados() {
                this.model.lista.forEach(x => {
                    x.results.forEach(res => {
                        this.ultimosResultadosResult(x, res);
                        res.originalValue = res.value;
                    })
                })
            },
            ultimosResultadosResult(x, res){
                const params = [
                    `pacienteId=${x.patientId}`,
                    `exameId=${res.lisTestCode}`,
                    `dataReferencia=${res.equipmentReceivedDatetime}`,
                ]

                if (!this.ultimosResultados.some(w => w.params[0] === params[0] && w.params[1] === params[1])) {
                    this.$http.get(`/api/interface/ultimosresultados?${params.join('&')}`)
                        .then(x => {                                    
                            if (x.data.ultimosResultados.length > 0) {
                                this.ultimosResultados.push({
                                    params: params,
                                    data: x.data
                                })
                            }
                        })
                }
            },
            selecionarTodos() {
                this.todosSelecionados = !this.todosSelecionados
                this.model.lista.forEach(x => {
                    x.confirmGuia = this.todosSelecionados
                    x.results.forEach(y => {
                        y.confirm = this.todosSelecionados
                    })
                })
            },
            refreshTest(result){
                this.deletando = true
                this.$http.post('/api/interface/refresh', result.resultId)
                    .then(res => {
                        this.$buefy.toast.open(`${this.$t('CONFIRMARINTERFACE.RESULTADOREPETIDO')} ${res.data} ${this.$t('CONFIRMARINTERFACE.REPETIDO')}`)
                        this.deletando = false
                    }, error => {
                        this.$buefy.toast.open({
                            message: this.$t('CONFIRMARINTERFACE.DELETANDO'),
                            type: 'is-danger'
                        })
                        this.deletando = false
                    })
            },
            refreshTestTime(observationDatetime , item){
                item.results.forEach(x => {
                    if(x.observationDatetime == observationDatetime){
                        this.refreshTest(x.resultId)
                    }
                })
            },
            confirmDeleteTime(observationDatetime , item){
                item.results.forEach(x => {
                    if(x.observationDatetime == observationDatetime){
                        this.confirmDelete(x, item)
                    }
                })
            },
            confirmTimeChange(observationDatetime , item){
                this.todosSelecionados = !this.todosSelecionados
                this.todosSelecionados = !this.todosSelecionados

                const confirm = item.results[0].confirm;

                item.results.forEach(x => {
                    if(x.observationDatetime == observationDatetime){
                        x.confirm = !confirm
                    }
                })
            },
            confirmGuiaChange(lisOrderID) {
                this.todosSelecionados = !this.todosSelecionados
                this.todosSelecionados = !this.todosSelecionados
                this.model.lista.forEach(x => {
                    if(x.lisOrderID == lisOrderID){
                        if(x.confirmGuia == null) {
                            x.confirmGuia = false;
                        }
                        x.confirmGuia = !x.confirmGuia;
                        x.results.forEach(y => {
                            y.confirm = x.confirmGuia
                        })
                    }
                })
            },
            procurarGuia() {
                this.$buefy.dialog.prompt({
                    message: this.$t('CONFIRMARINTERFACE.PROCURARGUIA'),
                    inputAttrs: {
                        type: 'number'
                    },
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),
                    cancelText: this.$t('CONFIRMARINTERFACE.CANCELAR'),                    
                    onConfirm: (value) => {
                        const elementId = 'guia_' + value
                        if (document.getElementById(elementId) == null) {

                            //buscar e incluir no final da lista
                            this.$http.get('/api/interface/confirm?id=' + this.$route.params.id + '&guiaId=' + value)
                                .then(res => {
                                    if(res.data == null || res.data.lista == null || res.data.lista.length == 0){
                                        this.$buefy.toast.open({
                                            message: this.$t('CONFIRMARINTERFACE.GUIANAOENCONTRADA') + value,
                                            type: 'is-danger'
                                        })
                                    }
                                    else {
                                        this.model.lista = this.model.lista.concat(res.data.lista);

                                        res.data.lista.forEach(x => {
                                            x.results.forEach(res => {
                                                this.ultimosResultadosResult(x, res);
                                            })
                                        })
                                        
                                        setTimeout(() => this.$scrollTo('#' + elementId), 10);                                        
                                    }
                                })
                        } else {
                            this.$scrollTo('#' + elementId)
                        }
                    }
                })
            },
            confirmDelete(result, testorder) {
                if (this.config.confirmarExclusaoNaConfirmacaoInterface) {
                    this.$buefy.dialog.confirm({
                        title: this.$t('CONFIRMARINTERFACE.EXCLUIRRESULTADO'),
                        message: this.$t('CONFIRMARINTERFACE.MENSAGEMDELETANDO') + result.lisTestName + '</b> (' + result.lisResultName + '): ' + result.value + ' ' + result.units,
                        confirmText: this.$t('CONFIRMARINTERFACE.EXCLUIR'),
                        cancelText: this.$t('CONFIRMARINTERFACE.CANCELAR'),
                        type: 'is-danger',
                        hasIcon: true,
                        onConfirm: () => {
                            this.deleteResult(result, testorder)
                        }
                    })
                } else {
                    this.deleteResult(result, testorder)
                }
            },
            deleteResult(result, testorder) {
                this.deletando = true
                this.$http.post('/api/interface/delete', result.resultId)
                    .then(res => {
                        testorder.results = testorder.results.filter(x => x.resultId != res.data)
                        this.$buefy.toast.open(`${this.$t('CONFIRMARINTERFACE.RESULTADO')} ${res.data} ${this.$t('CONFIRMARINTERFACE.EXCLUIDO')}`)
                        this.deletando = false
                    }, error => {
                        this.$buefy.toast.open({
                            message: this.$t('CONFIRMARINTERFACE.MENSAGEMERROEXCLUIR'),
                            type: 'is-danger'
                        })
                        this.deletando = false
                    })
            },
            editResult(result) {

                this.resultEdit = result;
                this.exibirModal = true;

            },
            verificarAbreviaturas(result){
                
                const busca =  {texto: result.value};

                this.$http.post('/api/atendimento/abreviaturas', busca).then(res => {
                    
                    if(res.data != null && res.data != ""){
                        this.resultEdit.value = res.data;
                    }
                });   

            },
            saveResult(result){

                this.$http.post('/api/atendimento/abreviaturas',  {texto: result.value}).then(res => {
                    
                    if(res.data != null && res.data != ""){
                        this.resultEdit.value = res.data;                        
                        result.originalValue = this.resultEdit.value;
                    }else{
                        result.originalValue = this.resultEdit.value;
                    }
                                
                    this.$http.post('/api/interface/edit', {
                        id: result.resultId,
                        value: result.value
                    }).then(res => {
                            if (res.status == 204) {
                                this.$buefy.toast.open({
                                    message: this.$t('CONFIRMARINTERFACE.MENSAGEMNAOALTERAR'),
                                    type: 'is-danger'
                                })
                            } else {
                                result.value = res.data.value
                                this.$buefy.toast.open({
                                    message: `${this.$t('CONFIRMARINTERFACE.RESULTADO')} ${res.data.id} ${this.$t('CONFIRMARINTERFACE.ALTERADO')} ${res.data.value}`,
                                    type: 'is-success'
                                })
                            }
                            this.exibirModal = false; 
                    });                    

                });  
                                

            },
            submit() {
                this.salvandoDados = true
                let confirmed = []
                this.model.lista.forEach(x => {
                    confirmed = confirmed.concat(x.results
                        .filter(y => y.confirm)
                        .map(w => {
                            return {
                                resultID: w.resultId,
                                lisOrderID: x.lisOrderID,
                                lisTestID: w.lisTestID,
                                lisResultCode: w.lisResultCode,
                                lisResultVersion: w.lisResultVersion,
                                value: w.value,
                                sendSigned: w.sendSigned,
                                confirm: true
                            }
                        })
                    )
                })
                this.$http.post('/api/interface/confirm', confirmed)
                    .then((res) => {
                        this.salvandoDados = false
                    })
                    .catch((error) => {
                        this.salvandoDados = false
                        throw error
                    });

                this.$buefy.toast.open({
                    message: this.$t('CONFIRMARINTERFACE.MENSAGEMRESULTADOS'),
                    type: 'is-success'
                })
                this.$router.push({ name: 'equipamentos' })
            }
        }
    }
</script>