<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('MODELOLAUDODESCRITIVO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome" maxlength="255" :has-counter="false"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('MODELOLAUDODESCRITIVO.SETOR')">
                                        <b-select v-model="model.setor.id" expanded>
                                            <option :value="0">Todos</option>
                                            <option
                                                v-for="option in setores"
                                                :value="option.value"
                                                :key="option.value">
                                                {{ option.value }} - {{ option.text }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('MODELOLAUDODESCRITIVO.UNIDADE')">
                                        <b-select v-model="model.unidade.id" expanded>
                                            <option :value="0">Todas</option>
                                            <option v-for="(item, index) in unidades"
                                                    v-bind:key="index"
                                                    :value="item.id">
                                                {{item.nome}}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>                                
                            </div>
                        </article>                                                
                    </div>
                </div>
                <div class="column is-12">
                    <ckeditor 
                        editorUrl="https://cdn.ckeditor.com/4.19.1/full-all/ckeditor.js"
                        v-model="model.html" 
                        :config="editorConfig"></ckeditor>     
                </div>                
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button v-if="isInRole('modelolaudosdescritivos-alterar')"
                                      type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('MODELOLAUDODESCRITIVO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('MODELOLAUDODESCRITIVO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>        
    </section>    
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import CKEditor from 'ckeditor4-vue'

    export default {
        data() {
            return {
                model: {
                    id: 0,
                    nome: null,
                    setor: {id: 0, nome: null},
                    unidade: {id: 0, nome: null},
                    html: null
                },
                salvandoDados: false,
                setores: [],
                editorConfig: {
                    // An array of stylesheets to style the WYSIWYG area.
                    // Note: it is recommended to keep your own styles in a separate file in order to make future updates painless.
                    contentsCss: [ 'https://cdn.ckeditor.com/4.19.1/full-all/contents.css', '/css/ckeditor-document.css', '/home/resultadocss' ],

                    // Define the toolbar: https://ckeditor.com/docs/ckeditor4/latest/features/toolbar.html
                    // The full preset from CDN which we used as a base provides more features than we need.
                    // Also by default it comes with a 3-line toolbar. Here we put all buttons in a single row.
                    toolbar: [
                        { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
                        { name: 'styles', items: [ 'Font', 'FontSize' ] },
                        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'CopyFormatting' ] },
                        { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
                        { name: 'align', items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
                        { name: 'links', items: [ 'Link', 'Unlink' ] },
                        { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote' ] },
                        { name: 'insert', items: [ 'Image', 'Table' ] },
                        { name: 'mode', items: [ 'Source' ] }
                    ],

                    // Since we define all configuration options here, let's instruct CKEditor to not load config.js which it does by default.
                    // One HTTP request less will result in a faster startup time.
                    // For more information check https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_config.html#cfg-customConfig
                    customConfig: '',

                    // Sometimes applications that convert HTML to PDF prefer setting image width through attributes instead of CSS styles.
                    // For more information check:
                    //  - About Advanced Content Filter: https://ckeditor.com/docs/ckeditor4/latest/guide/dev_advanced_content_filter.html
                    //  - About Disallowed Content: https://ckeditor.com/docs/ckeditor4/latest/guide/dev_disallowed_content.html
                    //  - About Allowed Content: https://ckeditor.com/docs/ckeditor4/latest/guide/dev_allowed_content_rules.html
                    disallowedContent: 'img{width,height,float}',
                    extraAllowedContent: 'img[width,height,align]',

                    // Enabling extra plugins, available in the full-all preset: https://ckeditor.com/cke4/presets-all
                    extraPlugins: 'tableresize,uploadimage,uploadfile',

                    /*********************** File management support ***********************/
                    // In order to turn on support for file uploads, CKEditor has to be configured to use some server side
                    // solution with file upload/management capabilities, like for example CKFinder.
                    // For more information see https://ckeditor.com/docs/ckeditor4/latest/guide/dev_ckfinder_integration.html

                    // Uncomment and correct these lines after you setup your local CKFinder instance.
                    // filebrowserBrowseUrl: 'http://example.com/ckfinder/ckfinder.html',
                    // filebrowserUploadUrl: 'http://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files',
                    /*********************** File management support ***********************/

                    // Make the editing area bigger than default.
                    height: 800,

                    // This is optional, but will let us define multiple different styles for multiple editors using the same CSS file.
                    bodyClass: 'document-editor',

                    // Reduce the list of block elements listed in the Format dropdown to the most commonly used.
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    format_tags: 'p;h1;h2;h3;pre',

                    // Simplify the Image and Link dialog windows. The "Advanced" tab is not needed in most cases.
                    removeDialogTabs: 'image:advanced;link:advanced',

                    // Define the list of styles which should be available in the Styles dropdown list.
                    // If the "class" attribute is used to style an element, make sure to define the style for the class in "mystyles.css"
                    // (and on your website so that it rendered in the same way).
                    // Note: by default CKEditor looks for styles.js file. Defining stylesSet inline (as below) stops CKEditor from loading
                    // that file, which means one HTTP request less (and a faster startup).
                    // For more information see https://ckeditor.com/docs/ckeditor4/latest/features/styles.html
                    stylesSet: [
                        /* Inline Styles */
                        { name: 'Marker', element: 'span', attributes: { 'class': 'marker' } },
                        { name: 'Cited Work', element: 'cite' },
                        { name: 'Inline Quotation', element: 'q' },

                        /* Object Styles */
                        {
                            name: 'Special Container',
                            element: 'div',
                            styles: {
                                padding: '5px 10px',
                                background: '#eee',
                                border: '1px solid #ccc'
                            }
                        },
                        {
                            name: 'Compact table',
                            element: 'table',
                            attributes: {
                                cellpadding: '5',
                                cellspacing: '0',
                                border: '1',
                                bordercolor: '#ccc'
                            },
                            styles: {
                                'border-collapse': 'collapse'
                            }
                        },
                        { name: 'Borderless Table', element: 'table', styles: { 'border-style': 'hidden', 'background-color': '#E6E6FA' } },
                        { name: 'Square Bulleted List', element: 'ul', styles: { 'list-style-type': 'square' } }
                    ]
                },
            }
        },
        components: {
            titlebar,
            'ckeditor': CKEditor.component,
        },
        mounted() {
            if (this.$route.params.id) {
                this.$http.get('/api/modeloLaudoDescritivo/cadastrar?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data
                    })
            }

            this.$http.get('/api/manutencao/setores?all=true')
                .then(res => {
                    this.setores = res.data
                }).catch(e => {
                    this.$buefy.toast.open({
                        message: 'Erro ao carregar os setores!',
                        type: 'is-danger'
                    })                    
                    throw e
                })
        },
        computed: {
            titleStack() {
                return [
                    this.$t('MODELOLAUDODESCRITIVO.MANUTENCAO'),
                    this.$t('MODELOLAUDODESCRITIVO.MODELOLAUDODESCRITIVO'),
                    this.$route.params.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([                
                'unidades'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true

                this.$http.post('/api/modeloLaudoDescritivo/salvar', this.model)
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.push({ name: 'modeloslaudodescritivo' })
                    })
                    .catch(err => {
                        this.salvandoDados = false;
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                    })
            }
        }
    }
</script>