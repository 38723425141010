<template>
    <section>
        <b-modal :active.sync="modalDesativaUsuarioIsOpened" has-modal-card trap-focus aria-role="dialog" aria-modal @close="desativaUsuarioPrincipal()">
            <modal-desativa-usuario v-bind.sync="modalDesativaUsuarioForm"></modal-desativa-usuario>
        </b-modal>
        <b-modal :active.sync="modalGerenciarUsuarioIsOpened" has-modal-card trap-focus aria-role="dialog" aria-modal width="70%">
            <modal-gerenciar-usuarios v-bind.sync="modalGerenciarUsuariosForm"></modal-gerenciar-usuarios>
        </b-modal>
        <titlebar :title-stack="titleStack" />
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONVENIOCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input v-model="model.nome" maxlength="100" :has-counter="true"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.CONTATO')">
                                        <b-input v-model="model.contato" maxlength="40" :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <buscaCepEndereco :cep.sync="model.cep"
                                              :endereco.sync="model.endereco"
                                              :bairro.sync="model.bairro"
                                              :cidadeid.sync="model.cidadeId"
                                              :cidadenome.sync="model.cidadeNome"></buscaCepEndereco>

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.FONE')">
                                        <b-input v-model="model.fone" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.CNPJ')">
                                        <b-input v-model="model.cnpj" v-mask="['##.###.###/####-##']"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.INSCRICAO')">
                                        <b-input v-model="model.inscricaoEstadual" maxlength="18" :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.INSCRICAOMUN')">
                                        <b-input v-model="model.inscricaoMunicipal" maxlength="12" :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.ENDERECORECOLHIMENTO')">
                                        <b-input v-model="model.enderecoRecolhimento" maxlength="200" :has-counter="false"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.HORARIOROTA')">
                                        <b-input v-model="model.horarioRota" v-mask="['##:##']" :has-counter="false"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.RESPONSAVELTRIAGEM')">
                                        <b-input v-model="model.responsavelTriagem" maxlength="150" :has-counter="false"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.TABPRECO')">
                                        <b-select v-model="model.tabelaPreco.id" class="fullwidth" expanded>
                                            <option v-for="option in tabelaPrecos"
                                                    :value="option.id"
                                                    :key="option.id">
                                                {{ option.nome }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column" v-if="tabelaPrecos.filter(tabelaPreco => tabelaPreco.id == model.tabelaPreco.id)[0].tipo == 'AMB'" >
                                    <b-field label="Valor CH">
                                        <b-input type="number" step=".00000001" v-model="model.valorCH"></b-input>
                                    </b-field>
                                </div>
                                <div class="column" v-if="tabelaPrecos.filter(tabelaPreco => tabelaPreco.id == model.tabelaPreco.id)[0].tipo == 'CBHPM'">
                                    <b-field label="Valor UCO">
                                        <b-input type="number" step=".00000001" v-model="model.valorUCO"></b-input>
                                    </b-field>
                                </div>
                                <div class="column" v-if="tabelaPrecos.filter(tabelaPreco => tabelaPreco.id == model.tabelaPreco.id)[0].tipo == 'CBHPM'">
                                    <b-field :label="$t('CONVENIOCADASTRO.DESCONTOPORTE')">
                                        <b-input type="number" step=".00000001" v-model="model.descontoNoPorte"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.DESCONTOMAX')">
                                        <b-input type="number" step="0.01" v-model="model.descontoMaximo" icon-right="percent"></b-input>
                                    </b-field>
                                </div>
                                <div class="column" v-if="tabelaPrecos.filter(tabelaPreco => tabelaPreco.id == model.tabelaPreco.id)[0].tipo == 'AMB' || tabelaPrecos.filter(tabelaPreco => tabelaPreco.id == model.tabelaPreco.id)[0].tipo == 'CBHPM'" >
                                    <b-field label="Valor Filme">
                                        <b-input type="number" step=".00000001" v-model="model.valorFilme"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.IRRF')">
                                        <b-input type="number" v-model="model.irrf" icon-right="percent"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.DESCONTO')">
                                        <b-input type="number" step="0.01" v-model="model.desconto" icon-right="percent"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.ANS')">
                                        <b-input type="number" v-model="model.registroANS" max="999999"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.CODLAB')">
                                        <b-input v-model="model.codigoLaboratorio" maxlength="14" :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-half">
                                    <b-field :label="$t('CONVENIOCADASTRO.TIPOCONV')">
                                        <b-select v-model="model.tipoConvenio.id" expanded>
                                            <option v-for="option in tiposDeConvenio"
                                                    :value="option.id"
                                                    :key="option.id">
                                                {{ option.nome }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column is-half">
                                    <b-field :label="$t('CONVENIOCADASTRO.TISS')">
                                        <b-select v-model="model.tipoTabelaTISS" expanded>
                                            <option v-for="option in tiposDeTabelas"
                                                    :value="option.id"
                                                    :key="option.id">
                                                {{ option.nome }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="email" name="e-mail" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONVENIOCADASTRO.EMAIL')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input maxlength="50" type="email" v-model="model.email" @blur="verificarEmail" :readonly="model.possuiUsuario"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.MODELO')">
                                        <b-select v-model="model.modeloResultado" expanded>
                                            <option v-for="option in modelos"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.MODELOCOMPROVANTE')">
                                        <b-select v-model="model.modeloComprovante" expanded>
                                            <option v-for="option in modelosComprovante"
                                                    :value="option.nome"
                                                    :key="option.nome">
                                                {{ option.nome }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>                                
                            </div>

                            <div class="columns">
                                <div class="column is-half">
                                    <b-field :label="$t('CONVENIOCADASTRO.REPRESENTANTE')">
                                        <b-input v-model="model.representante" maxlength="150" :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                                <div class="column is-half">
                                    <b-field :label="$t('CONVENIOCADASTRO.TABELACOPART')">
                                        <b-select v-model="model.tabelaPrecoCoparticipacao.id" class="fullwidth" expanded>
                                            <option value="0"></option>
                                            <option v-for="option in tabelaPrecosCoparticipacao"
                                                    :value="option.id"
                                                    :key="option.id">
                                                {{ option.nome }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.RAZAOSOCIAL')">
                                        <b-input v-model="model.razaoSocial" maxlength="150" :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.RESPLEGAL')">
                                        <b-input v-model="model.responsavelLegal" maxlength="150" :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.RESPTEC')">
                                        <b-input v-model="model.responsavelTecnico" maxlength="150" :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('CONVENIOCADASTRO.CPFRESP')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.cpf" v-mask="'###.###.###-##'">
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('CONVENIOCADASTRO.REGPROF')}}</label>
                                        <div class="control">
                                            <b-input v-model="model.registroProfissional" maxlength="50" :has-counter="true"></b-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('CONVENIOCADASTRO.TELRESP')}}</label>
                                        <div class="control">
                                            <b-input v-model="model.telResponsavelTecnico" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.CONTATORESULTADO')">
                                        <b-input v-model="model.contatoResultado" maxlength="150" :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('CONVENIOCADASTRO.TELEFONECONTATO')}}</label>
                                        <div class="control">
                                            <b-input v-model="model.foneContato" maxlength="20" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <ValidationProvider rules="email" name="e-mail" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONVENIOCADASTRO.EMAILCONTATO')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="email" v-model="model.emailContato" maxlength="50"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('CONVENIOCADASTRO.PRAZOPAGAMENTO')}}</label>
                                        <div class="control">
                                            <b-input v-model="model.prazoPagamento" maxlength="20"></b-input>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.SISTEMAUTILIZADO')">
                                        <b-input v-model="model.sistemaInterno" maxlength="100" :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('CONVENIOCADASTRO.RESPFINANCEIRO')">
                                        <b-input v-model="model.responsavelFinanceiro" maxlength="150" :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-3">
                                    <b-field :label="$t('CONVENIOCADASTRO.TEMPODESLOCAMENTO')">
                                        <b-field>
                                            <b-input type="number" v-model="model.tempoDeslocamento"></b-input>
                                            <p class="control">
                                                <span class="button is-static">horas</span>
                                            </p>
                                        </b-field>
                                    </b-field>
                                </div>
                                <div class="column is-5">
                                    <b-field :label="$t('CONVENIOCADASTRO.LIMITEAMOSTRA')">
                                        <b-field>
                                            <b-input type="number" v-model="model.horasAmostraAlerta"></b-input>
                                            <p class="control">
                                                <span class="button is-static">horas</span>
                                            </p>
                                        </b-field>
                                    </b-field>
                                </div>
                                <div class="column is-4">
                                    <b-field :label="$t('CONVENIOCADASTRO.NUMEROSADT')">
                                        <b-input type="number" v-model="model.numeroExamesSADT"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <searchIdName :id.sync="model.unidade.id" table="unidade" :label="$t('CONVENIOCADASTRO.UNIDADE')" />
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('CONVENIOCADASTRO.OBSERVACOES')}}</label>
                                        <div class="control">
                                            <textarea class="textarea" maxlength="500" v-model="model.observacoes"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </article>
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <article class="panel">
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.pacienteNaoVeResultado">
                                            {{$t('CONVENIOCADASTRO.ACESSORESULTADO')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.controleDeGuiasSADT">
                                            {{$t('CONVENIOCADASTRO.HABILITASADT')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <div v-if="!config.bloqueiaConfiguracaoInclusaoGuia" class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.podeIncluirGuia">
                                            {{$t('CONVENIOCADASTRO.PODEINCLUIR')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.naoAgruparAmostrasExistentes">
                                            {{$t('CONVENIOCADASTRO.NAOAGRUPAR')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.exibeObsGuia">
                                            {{$t('CONVENIOCADASTRO.EXIBIROBSERVACAO')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.portalNaoVerAmostrasIntegracao">
                                            {{$t('CONVENIOCADASTRO.VERAMOSTRA')}}
                                        </b-switch>
                                    </div>
                                </div>    
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.geraEtiquetaPreColeta">
                                            {{$t('CONVENIOCADASTRO.GERAETIQUETA')}}
                                        </b-switch>
                                    </div>
                                </div>       
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.enviaAnexoGuia">
                                            {{$t('CONVENIOCADASTRO.ENVIAANEXO')}}
                                        </b-switch>
                                    </div>
                                </div>                            
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.desativado">
                                            {{$t('CONVENIOCADASTRO.DESATIVADO')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.portalControleMedico">
                                            {{$t('CONVENIOCADASTRO.ESCOLHEMEDICO')}}
                                        </b-switch>
                                    </div>
                                </div>      
                                  <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.portalGuiaLiberada">
                                            {{$t('CONVENIOCADASTRO.GUIALIBERADA')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.inscricaoObrigatoria">
                                            {{$t('CONVENIOCADASTRO.INSCRICAOOBRIGATORIA')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.exibeFatura">
                                            {{$t('CONVENIOCADASTRO.EXIBEFATURA')}}
                                        </b-switch>
                                    </div>
                                </div>                                        
                            </article>

                            <historico-alteracoes :tabela="'Convenio'" :chave="model.id"></historico-alteracoes>                            

                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('CONVENIOCADASTRO.USUARIOS')}}
                                </div>
                                <div class="panel-block">
                                    <div v-if="isInRole('usuarioweb-criar') && model.possuiUsuario" class="field fullwidth">
                                        <b-button type="is-danger" class="fullwidth" @click="desativarUsuario">{{$t('CONVENIOCADASTRO.CANCELARUSUARIOS')}}</b-button>
                                    </div>
                                    <div class="field" v-if="!model.possuiUsuario">
                                        <b-switch v-model="criarUsuario">
                                            {{$t('CONVENIOCADASTRO.CRIARUSUARIO')}}
                                        </b-switch>
                                    </div>

                                </div>
                                <div class="panel-block">
                                    <b-button v-if="model.id" type="is-link" class="fullwidth" @click="gerenciarUsuarios">{{$t('CONVENIOCADASTRO.GERENCIAUSUARIO')}}</b-button>
                                </div>
                            </article>

                            <historicoAlteracoes tabela="historico" />

                            <article v-if="model.id != null" class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('CONVENIOCADASTRO.GUIAS')}}
                                </div>
                                <div class="panel-block is-justified-between">
                                    <b-button v-if="model.tipoConvenio.id && (!model.tipoConvenio.particular || (model.tipoConvenio.particular && model.tipoConvenio.reembolso )) && isInRole('convenio-atualizarprecos')" 
                                        @click="atualizarPrecos()">Atualizar</b-button>
                                    <b-button v-if="model.geraEtiquetaPreColeta" :title="$t('CONVENIOCADASTRO.GERAETIQUETA')"
                                        @click="gerarEtiquetasPreColeta()" icon-left="barcode"></b-button>
                                    <a :href="'/Manutencao/TabelaPrecoConvenio?convenioId=' + model.id" class="button is-link" :title="$t('CONVENIOCADASTRO.LISTARTABELA')">
                                        <b-icon icon="format-list-bulleted"></b-icon>
                                    </a>
                                    <router-link :to="{ name: 'guia-list', query: { convenioId: model.id }}" class="button is-info">
                                        {{$t('CONVENIOCADASTRO.LISTARGUIAS')}}
                                    </router-link>
                                </div>
                            </article>

                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    www.resultados.com.br
                                </div>
                                <div class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.publicaResultadosNaInternet">
                                            {{$t('CONVENIOCADASTRO.PUBLICARRESULTADO')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="panel-block" v-show="model.publicaResultadosNaInternet">
                                    <b-field :label="$t('CONVENIOCADASTRO.CHAVEINTERNET')"
                                             class="fullwidth">
                                        <b-input v-model="model.chaveInternet" class="is-large" maxlength="15" :has-counter="false"></b-input>
                                    </b-field>
                                </div>


                            </article>
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('CONVENIOCADASTRO.CODINTEGRACAO')}}
                                </div>
                                <div class="panel-block">
                                    <table class="table is-bordered is-striped is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th>{{$t('CONVENIOCADASTRO.TIPO')}}</th>
                                                <th>{{$t('CONVENIOCADASTRO.CODIGO')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in model.codigosIntegrador" v-bind:key="index">
                                                <td>
                                                    <b-select placeholder="Integrador" v-model="item.integradorConvenioId.integradorId">
                                                        <option v-for="option in integradores"
                                                                :value="option.id"
                                                                :key="option.id">
                                                            {{ option.nome }}
                                                        </option>
                                                    </b-select>
                                                </td>
                                                <td>
                                                    <input class="input" type="text" v-model="item.integradorConvenioId.codigo">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="panel-block">
                                    <button type="button" class="button is-info is-fullwidth" @click="addCodigo">
                                        {{$t('CONVENIOCADASTRO.ADDCODIGO')}}
                                    </button>
                                </div>
                            </article>
                        </article>
                    </div>
                </div>
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('CONVENIOCADASTRO.TISSCADASTRO')}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-2">
                                    <b-field :label="$t('CONVENIOCADASTRO.VERSAO')">
                                        <b-select v-model="model.tissVersao" expanded>
                                            <option v-for="option in tissVersoes"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column is-6">
                                    <b-field :label="$t('CONVENIOCADASTRO.URLWEB')">
                                        <b-input v-model="model.tissWsUrl" maxlength="255" :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                                <div class="column is-2">
                                    <b-field :label="$t('CONVENIOCADASTRO.LOGIN')">
                                        <b-input v-model="model.tissWsLogin" maxlength="64" :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                                <div class="column is-2">
                                    <b-field :label="$t('CONVENIOCADASTRO.SENHA')">
                                        <b-input v-model="model.tissWsSenha" maxlength="64" :has-counter="true"></b-input>
                                    </b-field>
                                </div>
                            </div>

                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('convenio-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle"
                                      >
                                {{$t('CONVENIOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('CONVENIOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<style>
    .fullwidth {
        width: 100%;
    }
</style>

<script>
    import { mapGetters, mapState } from 'vuex'
    import buscaCepEndereco from '@/components/buscaCepEndereco.vue'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import modalDesativaUsuario from '@/components/manutencao/modalDesativaUsuario.vue'
    import modalGerenciarUsuarios from '@/components/manutencao/modalGerenciarUsuarios.vue'
    import modalAtualizaPrecos from '@/components/manutencao/modalAtualizaPrecos'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    bairro: null,
                    cep: null,
                    chaveInternet: null,
                    cidadeId: null,
                    cidadeNome: null,
                    cnpj: null,
                    codigoIntegracao: null,
                    codigoLaboratorio: null,
                    codigosIntegrador: [],
                    contato: null,
                    contatoResultado: null,
                    controleDeGuiasSADT: false,
                    cpf: null,
                    desativado: false,
                    desconto: null,
                    descontoMaximo: null,
                    descontoNoPorte: null,
                    email: null,
                    emailContato: null,
                    endereco: null,
                    exibeObsGuia: false,
                    portalNaoVerAmostrasIntegracao: false,
                    fone: null,
                    foneContato: null,
                    horasAmostraAlerta: null,
                    inscricaoEstadual: null,
                    inscricaoMunicipal: null,
                    irrf: null,
                    modeloResultado: null,
                    modeloComprovante: null,
                    naoAgruparAmostrasExistentes: false,
                    nome: null,
                    observacoes: null,
                    pacienteNaoVeResultado: false,
                    planoIntegracao: null,
                    podeIncluirGuia: false,
                    prazoPagamento: null,
                    publicaResultadosNaInternet: false,
                    razaoSocial: null,
                    registroANS: null,
                    registroProfissional: null,
                    representante: null,
                    responsavelFinanceiro: null,
                    responsavelLegal: null,
                    responsavelTecnico: null,
                    sistemaInterno: null,
                    tabelaPreco: { id: null, nome: null },
                    tabelaPrecoCoparticipacao: { id: null, nome: null },
                    telResponsavelTecnico: null,
                    tempoDeslocamento: null,
                    tipoConvenio: { id: null, nome: null, particular: false},
                    tipoTabelaTISS: null,
                    tissVersao: null,
                    tissWsImplementado: null,
                    tissWsLogin: null,
                    tissWsSenha: null,
                    tissWsUrl: null,
                    unidade: { id: null, nome: null },
                    valorCH: null,
                    valorUCO: null,
                    possuiUsuario: false,
                    numeroExamesSADT: 0,
                    valorFilme: null,
                    enderecoRecolhimento: null,
                    horarioRota: null,
                    responsavelTriagem: null,
                    inscricaoObrigatoria: false,
                    exibeFatura: false,
                },
                criarUsuario: false,
                capturando: false,
                camera: null,
                deviceId: null,
                devices: [],
                carregandoHistoricoArquivos: true,
                historicoArquivos: [],
                salvandoDados: false,
                nomeSocial: false,
                criarGuia: false,
                tabelaPrecos: [],
                tiposDeConvenio: [],
                modelos: [],
                modelosComprovante: [],
                tissVersoes: [],
                tiposDeTabelas: [],
                usuarioDesativado: false,
                modalDesativaUsuarioIsOpened: false,
                modalGerenciarUsuarioIsOpened: false,
                modalGerenciarUsuariosForm: {
                    usuarios: [],
                    titulo: 'Usuários Secundários',
                    idConvenio: null,
                },
                modalDesativaUsuarioForm: {
                    removeSecundarios: false,
                    titulo: 'Desativar Usuario Web',
                    success: false
                }
            };
        },
        components: {
            buscaCepEndereco,
            titlebar,
            searchIdName,
            historicoAlteracoes,
            modalDesativaUsuario,
            modalGerenciarUsuarios
        },
        created() {
            this.$http.get('/api/atendimento/ListarComprovanteModelos')
                .then(res => {
                    this.modelosComprovante = res.data;
                })

            this.$http.get('/api/manutencao/convenioDados')
                .then(res => {
                    this.tabelaPrecos = res.data?.tabelasDePrecos;
                    this.tabelaPrecosCoparticipacao = res.data?.tabelasDePrecosCoparticipacao;
                    this.tiposDeConvenio = res.data?.tiposDeConvenio;
                    this.modelos = res.data?.modelos;
                    this.tissVersoes = res.data?.tissVersoes;
                    this.tiposDeTabelas = res.data?.tiposDeTabelas;
                    // this.integratores = res.data?.integratores;
                    if (!this.$route.params.id) {
                        this.model.tabelaPreco.id = this.tabelaPrecos[0]?.id;
                        this.model.tabelaPrecoCoparticipacao.id = this.tabelaPrecosCoparticipacao[0]?.id;
                        this.model.tipoConvenio.id = this.tiposDeConvenio[0]?.id;
                        this.model.tipoTabelaTISS = this.tiposDeTabelas[0]?.id;
                        this.model.unidade.id = 0;
                    }
                })

            if (this.$route.params.id) {
                this.$http.get('/api/manutencao/convenio?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;
                        if (!this.model.tabelaPreco) {
                            this.model.tabelaPreco = { id: 0, nome: null };
                        }
                        if (!this.model.tabelaPrecoCoparticipacao) {
                            this.model.tabelaPrecoCoparticipacao = { id: 0, nome: null };
                        }
                        if (!this.model.unidade || !this.model.unidade?.id) {
                            this.model.unidade = { id: 0, nome: null };
                        }
                        if (!this.model.tipoConvenio) {
                            this.model.tipoConvenio = { id: 0, nome: null };
                        }
                        if (this.model.codigosIntegrador?.length) {
                            this.model.codigosIntegrador = this.model.codigosIntegrador.map(c => {
                                return {
                                    integradorConvenioId: {
                                        integradorId: c.integradorId,
                                        codigo: c.codigo
                                    }
                                }
                            })
                        } if (this.model.desativado) {
                            this.usuarioDesativado = true;
                        }
                        this.modalGerenciarUsuariosForm.idConvenio = this.model.id;
                        console.log(this.model)
                    })
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('CONVENIOCADASTRO.MANUTENCAO'),
                    this.$t('SISTEMA.CONVENIO'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            idade() {
                return ~~((Date.now() - this.model.dataDeNascimento) / (31557600000));
            },
            device() {
                return this.devices.find(n => n.deviceId === this.deviceId);
            },
            ...mapState([
                'unidadeId',
                'config',
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {
        },
        methods: {
            onSubmit() {
                const novoItem = Object.assign({}, this.model);

                if (novoItem.tabelaPreco && !novoItem.tabelaPreco.tipo) {
                    novoItem.tabelaPreco.tipo = 0;
                }

                if (novoItem.tabelaPrecoCoparticipacao && !novoItem.tabelaPrecoCoparticipacao.tipo) {
                    novoItem.tabelaPrecoCoparticipacao.tipo = 0;
                }

                if (novoItem.tabelaPrecoCoparticipacao != null && novoItem.tabelaPrecoCoparticipacao.id == null) {
                    novoItem.tabelaPrecoCoparticipacao.id = 0;
                }

                if (!novoItem.tissWsImplementado) {
                    delete novoItem.tissWsImplementado;
                }

                if (!this.$route.params.id) {
                    delete novoItem.id;
                }

                if (novoItem.cidadeId != null) {
                    novoItem.cidade = {
                        id: novoItem.cidadeId,
                        nome: 'CIDADE', // qq coisa pra passar da validação
                        estado: 'ESTADO' // qq coisa pra passar da validação
                    }
                }

                if (novoItem.codigosIntegrador?.length) {
                    novoItem.codigosIntegrador = novoItem.codigosIntegrador.filter(c => c.integradorConvenioId.integradorId != null)?.map(i => {
                        return {
                            codigo: i.integradorConvenioId.codigo,
                            ...i
                        }
                    });
                }

                this.salvandoDados = true;
                this.$http.post(`/api/manutencao/convenio?criarUsuario=${this.criarUsuario}`, novoItem)
                    .then(() => {
                        this.$route.params.guia ? this.$router.back() : this.$router.push({ name: 'manutencao' })
                    })
                    .catch(err => {
                        let erro = ''
                        Object.keys(err.body.errors).forEach(x => {
                            erro += '\n' + x + ": " + err.body.errors[x]
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('CONVENIOCADASTRO.ERROSALVAR') + erro,
                            type: 'is-danger',
                            queue: false
                        })
                    })
                    .finally(() => {
                        this.salvandoDados = false;
                    });
            },
            addCodigo() {
                this.model.codigosIntegrador.push({
                    integradorConvenioId: {
                        integradorId: null
                    }

                });
            },
            desativarUsuario() {
                this.modalDesativaUsuarioIsOpened = true;
            },
            async gerenciarUsuarios() {
                const res = await this.$http.get(`/api/manutencao/UsuarioSecundariosConvenio?convenioId=${this.model.id}`);
                this.modalGerenciarUsuariosForm.usuarios = [...res.data, { id: null, email: '', acessaPrecos: false, recebeEmail: false, reenviarConfirmacao: false, incluirGuia: false, acessaResultado: false }];
                this.modalGerenciarUsuarioIsOpened = true;
            },
            verificarEmail() {
                this.$http.get(`/api/manutencao/VerificarUsuarioEmail?email=${this.model.email}`)
                    .then(res => {
                        if (res.data?.existe && !(res.data.tipo == 'convênio' && res.data.id == this.model.id)) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('CONVENIOCADASTRO.OEMAIL') + res.data.email + this.$t('CONVENIOCADASTRO.EMUSO') + res.data.tipo + ' código ' + res.data.id,
                                type: 'is-danger',
                                queue: false
                            })
                            this.model.email = null
                        }
                    });
            },
            desativaUsuarioPrincipal(){
                if (this.modalDesativaUsuarioForm.success){
                    this.modalDesativaUsuarioForm.success = false;
                    this.$http.get(`/api/manutencao/deletarUsuarioConvenio?convenioId=${this.model.id}&desativarSecundarios=${this.modalDesativaUsuarioForm.removeSecundarios}`)
                        .then(() => {
                            this.$router.back()
                        })
                        .catch(err => {
                            console.error(err)
                            Object.keys(err.body.errors).forEach(x => {
                                this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                            })
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('CONVENIOCADASTRO.ERROUSUARIO') + err.body.title,
                                type: 'is-danger',
                                queue: false
                            })
                        })
                }
            },
            atualizarPrecos(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAtualizaPrecos,
                    props: {
                        convenioId: this.model.id
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true,
                });
            },
            gerarEtiquetasPreColeta(){
                this.$buefy.dialog.prompt({
                    title: this.$t('CONVENIOCADASTRO.ETIQUETAPRECOLETA'),
                    message: this.$t('CONVENIOCADASTRO.QUANTIDADEETIQUETA'),
                    inputAttrs: {
                        type: 'number',
                        placeholder: this.$t('CONVENIOCADASTRO.QUANTIDADE')
                    },
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),
                    cancelText: this.$t('CONVENIOCADASTRO.CANCELAR'),
                    trapFocus: true,
                    onConfirm: (res) => {
                        this.$notificacaoHub.invoke("EtiquetaPreColeta",
                            this.model.id, parseInt(res))
                            .then(() => {
                                this.$buefy.snackbar.open({
                                    duration: 2000,
                                    message: this.$t('CONVENIOCADASTRO.IMPRIMIRETIQUETA'),
                                    type: 'is-success',
                                    position: 'is-top-right',
                                    queue: false
                                })
                            })
                            .catch(e => {
                                return console.error(e.toString());
                            })
                    }
                });
            }
        }
    }
</script>