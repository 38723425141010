<template>
    <section>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <div class="box">
            <b-tabs v-model="tabs" multiline>
                <b-tab-item label="Filtro">
                    <div class="columns is-multiline">
                        <div class="column is-6">
                            <searchIdName :id.sync="unidade.id" :item.sync="unidade" table="Unidade" label="Unidade"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="localDeOrigem.id" :item.sync="localDeOrigem" table="Local" label="Local de Origem"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="localDeEntrega.id" :item.sync="localDeEntrega" table="Local" label="Local de Entrega"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="paciente.id" :item.sync="paciente" table="Paciente" label="Paciente"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="medico.id" :item.sync="medico" table="Medico" label="Médico"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="convenio.id" :item.sync="convenio" table="Convenio" label="Convênio"/>
                        </div>
                        <div class="column is-6">
                            <div class="panel">
                                <p class="panel-tabs">
                                    <a :class="{ 'is-active': dataFiltro == 'emissao' }" @click="dataFiltro = 'emissao'">Emissão</a>
                                    <a :class="{ 'is-active': dataFiltro == 'solicitacao' }" @click="dataFiltro = 'solicitacao'">Solicitação</a>
                                    <a :class="{ 'is-active': dataFiltro == 'retorno' }" @click="dataFiltro = 'retorno'">Retorno</a>
                                </p>
                                <div class="panel-block">
                                    <periodoHora :dataInicial.sync="datas[0]"
                                        :dataFinal.sync="datas[1]"/>
                                </div>
                            </div>                    
                        </div>                
                        <div class="column is-6">
                            <b-field>
                                <b-checkbox v-model="apenasResultadosNaoImpressos">Retornar apenas resultados não impressos</b-checkbox>
                            </b-field>
                            <b-field>
                                <b-checkbox v-model="apenasResultadosSemEmailMedico">Retornar apenas resultados sem envio de e-mail para Solicitante</b-checkbox>
                            </b-field>
                            <b-field>
                                <b-checkbox v-model="apenasResultadosSemEmailPaciente">Retornar apenas resultados sem envio de e-mail para Paciente</b-checkbox>
                            </b-field>
                            <b-field>
                                <b-checkbox v-model="apenasResultadosSemEmailConvenio">Retornar apenas resultados sem envio de e-mail para Convênio</b-checkbox>
                            </b-field>
                            <b-field>
                                <b-checkbox v-model="apenasResultadosSemVisualizacao"> Retornar apenas resultados não visualizados em PDF</b-checkbox>
                            </b-field>                                                       
                            <b-field>
                                <b-checkbox v-model="guiasApenasCompletas">Retornar apenas guias completas</b-checkbox>
                            </b-field> 
                            <div class="columns is-multiline">
                                <div class="column is-4">
                                    <b-radio v-model="ordena"
                                        name="ordena"
                                        title="Ordenar"
                                        native-value="guia">
                                        Nro da Guia
                                    </b-radio>
                                </div>
                                <div class="column is-4">
                                    <b-radio v-model="ordena"
                                        name="ordena"
                                        title="Ordenar"
                                        native-value="paciente">
                                        Paciente
                                    </b-radio>
                                </div>
                                <div class="column is-4">
                                    <b-radio v-model="ordena"
                                        name="ordena"
                                        title="Ordenar"
                                        native-value="emissao">
                                        Data de Emissão
                                    </b-radio>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="has-text-centered">
                        <b-button type="is-success" :loading="loading" @click="carregarGuias()">Filtrar</b-button>
                    </div>
                </b-tab-item>
                <b-tab-item label="Selecionados">
                    <article>
                        <div>
                            <div class="is-light">
                                <label><strong>Código da Guia</strong></label>
                                <div class="field has-addons">
                                    <div class="control">
                                        <input type="text" v-model="guiaId" class="input" ref="guiaId"/>
                                    </div>
                                    <div class="control">
                                        <a class="button" @click="incluirGuia();">
                                        Incluir
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </b-tab-item>
            </b-tabs>
        </div>
        
        <div class="box" v-if="model.length > 0">
            <b-table :data="model"
                 :loading="loading"
                 striped
                 hoverable
                :checked-rows.sync="checkedRows"
                checkable
                :checkbox-position="checkboxPosition">

                <template >
                    <b-table-column  v-slot="props" field="Número da Guia" label="Número da Guia">
                        <router-link :to="{ name: 'guia', params: { id: props.row.id }}">
                            {{ props.row.id }}
                        </router-link>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Referencia" label="Referencia">
                        <small>{{ props.row.referencia }}</small>
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Paciente" label="Paciente">
                        {{ props.row.pacienteNome }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Convenio" label="Convênio">
                        {{ props.row.convenioNome }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Medico" label="Solicitante">
                        {{ props.row.medicoNome }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Local de Origem" label="Local de Origem">
                        {{ props.row.localDeOrigemNome }}
                    </b-table-column>

                    <b-table-column  v-slot="props" field="Emissao" label="Emissão">
                        <span v-if="props.row.dataDeEmissao">
                            {{ $moment(props.row.dataDeEmissao).format("DD/MM/YYYY") }}
                        </span>
                    </b-table-column>

                </template>
            </b-table>
        </div>
        <br>
        <nav class="level" v-if="model.length > 0">
            <div class="level-item">
                <div class="buttons">
                    <b-button type="is-info"
                                :loading="loading"
                                @click="enviarEmailPaciente()"
                                icon-left="check-circle">
                        Enviar por email paciente
                    </b-button>
                    <b-button type="is-info"
                                :loading="loading"
                                @click="enviarEmailMedico()"
                                icon-left="check-circle">
                        Enviar por email solicitante
                    </b-button>
                    <b-button type="is-info"
                                :loading="loading"
                                @click="enviarEmailConvenio()"
                                icon-left="check-circle">
                        Enviar por email convênio
                    </b-button>
                    <form action="/Home/ImpressaoPdf" method="post">
                        <input type="hidden" name="apenasNaoImpressos" :value="apenasResultadosNaoImpressos" />
                        <input type="hidden" name="descricao" value="Resultado" />
                        <input type="hidden" name="loteResultados" value="true" />
                        
                        <input v-for="(item, index) in checkedRows" v-bind:key="index" type="hidden" name="guiaExames" :value="item.id" />

                        <b-button type="is-success"
                                :loading="loading"
                                native-type="submit"
                                icon-left="check-circle">
                            Imprimir Lote
                        </b-button>
                    </form>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                        Voltar
                    </b-button>
                </div>
            </div>
        </nav>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import periodoHora from '@/components/periodoHora.vue'

    export default {
        data() {
            return {
                model: [],
                unidade: {
                    id: null
                },
                localDeOrigem: {
                    id: null
                },
                localDeEntrega: {
                    id: null
                },
                paciente: {
                    id: null
                },
                medico: {
                    id: null
                },
                convenio: {
                    id: null
                },
                datas: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ],
                dataFiltro: 'emissao',
                loading: false,
                checkedRows: [],
                checkboxPosition: 'left',
                ordena: 'guia',
                apenasResultadosNaoImpressos: false,
                apenasResultadosSemEmailMedico: false,
                apenasResultadosSemEmailPaciente: false,
                apenasResultadosSemEmailConvenio: false,
                apenasResultadosSemVisualizacao: false,
                guiasApenasCompletas: false,
                tabs: 0,
                guiaId: ""
            };
        },
        components: {
            titlebar,
            searchIdName,
            periodoHora
        },
        computed: {
            titleStack() {
                return [
                    'Análise',
                    'Lote resultados'
                ]
            },          
            ...mapState([
                'unidadeId',
                'config',
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            carregarGuias(){
                
                const params = [
                    `ordenarPor=${this.ordena}`,
                    `filtrarPor=${this.dataFiltro}`,
                    `retornarLista=true`,
                    `dataInicial=${this.$moment(this.datas[0]).format('YYYY-MM-DDTHH:mm:ss') }`,
                    `dataFinal=${this.$moment(this.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`
                ]

                if(this.unidade != null && this.unidade.id != null){
                    params.push(`unidadeId=${this.unidade.id}`);
                }

                if(this.localDeOrigem != null && this.localDeOrigem.id != null){
                    params.push(`localDeOrigemId=${this.localDeOrigem.id}`);
                }

                if(this.localDeEntrega != null && this.localDeEntrega.id != null){
                    params.push(`localDeEntregaId=${this.localDeEntrega.id}`);
                }

                if(this.paciente != null && this.paciente.id != null){
                    params.push(`pacienteId=${this.paciente.id}`);
                }

                if(this.medico != null && this.medico.id != null){
                    params.push(`medicoId=${this.medico.id}`);
                }

                if(this.convenio != null && this.convenio.id != null){
                    params.push(`convenioId=${this.convenio.id}`);
                }

                if (this.apenasResultadosNaoImpressos) {
                    params.push('apenasNaoImpressos=true')
                }

                if (this.apenasResultadosSemEmailMedico) {
                    params.push('apenasSemEmailMedico=true')
                }

                if (this.apenasResultadosSemEmailPaciente) {
                    params.push('apenasSemEmailPaciente=true')
                }

                if (this.apenasResultadosSemEmailConvenio) {
                    params.push('apenasSemEmailConvenio=true')
                }

                if (this.guiasApenasCompletas) {
                    params.push('apenasGuiasCompletas=true')
                }

                if (this.apenasResultadosSemVisualizacao) {
                    params.push('apenasResultadosSemVisualizacao=true')
                }                
                                
                this.loading = true;
                this.$http.get(`/api/Analitico/LoteResultados?${params.join('&')}`)
                    .then(({ data }) => {
                        this.model = data
                        this.checkedRows = data
                        this.loading = false
                    })
                    .catch((error) => {
                        this.loading = false
                        throw error
                    })

            },
            enviarEmailPaciente(){
                this.loading = true;

                this.$http.post('/api/analitico/EmailPacienteLote', this.checkedRows.map(item => item.id))
                    .then(() => {
                        this.loading = false;
                    })
            },
            enviarEmailMedico(){
                this.loading = true;

                this.$http.post('/api/analitico/EmailMedicoLote', this.checkedRows.map(item => item.id))
                    .then(() => {
                        this.loading = false;
                    })
            },
            enviarEmailConvenio(){
                this.loading = true;

                this.$http.post('/api/analitico/EmailConvenioLote', this.checkedRows.map(item => item.id))
                    .then(() => {
                        this.loading = false;
                    })
            },
            incluirGuia(){
                this.loading = true;

                this.$http.get('/api/atendimento/BuscarGuia?id=' + this.guiaId)
                .then(res => res.data)
                .then(data => {
                    console.log(data);

                    data.dataDeEmissao = new Date(data.dataDeEmissao);
                    console.log(data);

                    this.model.push(data);
                    this.checkedRows.push(data);

                    this.$refs.guiaId.focus();
                    this.loading = false;
                });

                this.guiaId = "";
            }
        }
    }
</script>