<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <b-table :data="model" class="table-fix"
                 :loading="loading"
                 striped
                 hoverable>

            <template >
                <b-table-column  v-slot="props" field="guiaId" label="Guia">
                    <router-link :to="'/atendimento/guia/'+props.row.guiaId" class="is-primary is-small is-pulled-right">
                        {{ props.row.guiaId }}
                    </router-link>
                </b-table-column>

                <b-table-column v-slot="props" field="Paciente" label="Paciente">
                    {{ props.row.pacienteNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Convenio" label="Convênio">
                    {{ props.row.convenioNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Data" label="Data de Emissão">
                    {{ $moment(props.row.dataHora).format("DD/MM/YYYY HH:mm")}}
                </b-table-column>

                <b-table-column  v-slot="props" field="dados" label="Tipo">
                    {{ props.row.dados }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Amostra" label="Amostra">
                    {{ props.row.amostraCodigoDeBarras }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Exames" label="Exames">
                    <span v-for="(item, index) in props.row.examesApelidos" v-bind:key="index" class="tag is-default">
                        {{item}}<span> </span>
                    </span>
                </b-table-column>

            </template>
        </b-table>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: [],
                loading: false
            };
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            titleStack() {
                return [
                    'Análise',
                    'Amostras com Incidência'
                ]
            },                 
        },
        components: {
            titlebar
        },        
        mounted() {            
            const params = [
                `unidadeId=${this.$route?.query?.unidadeId}`
            ].join('&')

            this.loading = true
            this.$http.get(`/api/analitico/amostrasIncidencia?${params}`)
                .then(({ data }) => {
                    this.model = data;
                    this.loading = false
                })
                .catch((error) => {
                    this.model = []
                    this.loading = false
                    throw error
                })
        }
    }
</script>