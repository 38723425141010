<template>
    <section>
        <b-collapse class="card has-background-transparent"
                    animation="slide"
                    :open="isOpen == 0"
                    @open="isOpen = 0;salvarCollapseIndex()">
            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    {{$t('MANUTENCAOLISTAR.PESSOAS')}}
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>
            </div>
            <div class="card-content is-paddingless">
                <div class="columns">
                    <div class="column is-4">
                        <div v-if="isInRole('paciente-alterar') || isInRole('paciente-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.PACIENTES')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'pacientes', query: { procurar: procurar.paciente }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                        v-model="procurar.paciente"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'pacientes' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('paciente-alterar')" :to="{ name: 'paciente' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column is-4" v-if="config.laboratorioVeterinario">
                        <div v-if="isInRole('especie-alterar') || isInRole('especie-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.ESPECIE')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'especies', query: { procurar: procurar.especie }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                        v-model="procurar.especie"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'especies' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('especie-alterar')" :to="{ name: 'especie' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column is-4" v-if="config.laboratorioVeterinario">
                        <div v-if="isInRole('raca-alterar') || isInRole('raca-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.RACA')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'racas', query: { procurar: procurar.raca }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                        v-model="procurar.raca"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'racas' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('raca-alterar')" :to="{ name: 'raca' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">

                    <div class="column is-4">
                        <div v-if="isInRole('medico-alterar') || isInRole('medico-consultar')" class="panel">
                            <p class="panel-heading background-2">
                               {{$t('MANUTENCAOLISTAR.MEDICOS')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'medicos', query: { procurar: procurar.medico }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.medico"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'medicos' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                  </router-link>
                                <router-link v-if="isInRole('medico-alterar')" :to="{ name: 'medico' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column is-4">
                        <div v-if="isInRole('Administrador')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.FUNCIONARIO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'funcionarios', query: { procurar: procurar.funcionario }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.funcionario"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'funcionarios' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                  </router-link>
                                <router-link v-if="isInRole('Administrador')" :to="{ name: 'funcionario' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column is-4">
                        <div v-if="isInRole('Administrador')" class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.NIVELACESSO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'niveisdeacesso', query: { procurar: procurar.niveldeacesso }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.niveldeacesso"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'niveisdeacesso' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link :to="{ name: 'niveldeacesso' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </b-collapse>
        <b-collapse class="card has-background-transparent"
                    animation="slide"
                    :open="isOpen == 1"
                    @open="isOpen = 1;salvarCollapseIndex()">
            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    {{$t('MANUTENCAOLISTAR.EMPRESA')}}
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>
            </div>
            <div class="card-content is-paddingless">
                <div class="columns">
                    <div class="column">
                        <div v-if="isInRole('convenio-alterar') || isInRole('convenio-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('SISTEMA.CONVENIO')}}s
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'convenios', query: { procurar: procurar.convenio }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.convenio"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'convenios' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('convenio-alterar') && 
                                integradores.filter(x => x.ativo && x.versao == 30).length == 0" 
                                    :to="{ name: 'convenio' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-if="isInRole('tipoConvenio-alterar') || isInRole('tipoConvenio-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.TIPOCONVENIO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'tiposdeconvenios', query: { procurar: procurar.tipoConvenio }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.tipoConvenio"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'tiposdeconvenios' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('tipoConvenio-alterar')" :to="{ name: 'tipodeconvenio' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-if="isInRole('fornecedor-alterar') || isInRole('fornecedor-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.FORNECEDOR')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'fornecedores', query: { procurar: procurar.fornecedor }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.fornecedor"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>                           
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'fornecedores' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('fornecedor-alterar')" :to="{ name: 'fornecedor' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-collapse>
        <b-collapse class="card has-background-transparent"
                    animation="slide"
                    :open="isOpen == 2"
                    @open="isOpen = 2; salvarCollapseIndex()">
            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    {{$t('MANUTENCAOLISTAR.EXAMES')}}
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>
            </div>
            <div class="card-content is-paddingless">
                <div class="columns">
                    <div class="column">
                         <div v-if="isInRole('exame-alterar') || isInRole('exame-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.EXAMES')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'exames', query: { procurar: procurar.exame }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.exame"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'exames' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('exame-alterar')" :to="{ name: 'exame' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>                       
                    </div>
                    <div class="column">
                        <div v-if="isInRole('material-alterar') || isInRole('material-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.MATERIAIS')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'materiais', query: { procurar: procurar.material }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.material"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'materiais' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('material-alterar')" :to="{ name: 'material' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-if="isInRole('conservante-alterar') || isInRole('conservante-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.CONSERVANTES')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'conservantes', query: { procurar: procurar.conservante }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.conservante"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'conservantes' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('conservante-alterar')" :to="{ name: 'conservante' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                     <div class="column">
                        <div v-if="isInRole('mascaraAbreviatura-alterar') || isInRole('mascaraAbreviatura-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.ABREVIATURAS')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'abreviaturas', query: { procurar: procurar.mascaraAbreviatura }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.mascaraAbreviatura"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form> 
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'abreviaturas' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('mascaraAbreviatura-alterar')" :to="{ name: 'abreviatura' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div v-if="isInRole('exameGrupo-alterar') || isInRole('exameGrupo-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.GRUPOEXAME')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'grupoexames', query: { procurar: procurar.exameGrupo }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.exameGrupo"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'grupoexames' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('exameGrupo-alterar')" :to="{ name: 'grupoexame' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>                       
                    </div>
                    <div class="column">
                        <div v-if="isInRole('exameCodigoTipo-alterar') || isInRole('exameCodigoTipo-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.TIPOCODIGO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'examescodigostipos', query: { procurar: procurar.exameCodigoTipo }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.exameCodigoTipo"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'examescodigostipos' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('exameCodigoTipo-alterar')" :to="{ name: 'examecodigotipo' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">                        
                        <div v-if="isInRole('setor-alterar') || isInRole('setor-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.SETORES')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'setores', query: { procurar: procurar.setor }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.setor"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'setores' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('setor-alterar')" :to="{ name: 'setor' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-if="isInRole('departamento-alterar') || isInRole('departamento-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.DEPARTAMENTOS')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'departamentos', query: { procurar: procurar.departamento }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.departamento"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'departamentos' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('departamento-alterar')" :to="{ name: 'departamento' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div v-if="isInRole('tabelapreço-alterar') || isInRole('tabelapreço-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.TABELASPRECO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'tabelasprecos', query: { procurar: procurar.tabelapreco }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.tabelapreco"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'tabelasprecos' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('tabelapreço-alterar')" :to="{ name: 'novatabelapreco' }"  class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-if="isInRole('valorescbphm-alterar') || isInRole('valorescbphm-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.VALORES')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'valorescbhpm', query: { procurar: procurar.valorescbphm }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.valorescbphm"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'valorescbhpm' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('valorescbphm-alterar')" :to="{ name: 'valorcbhpm' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>                        
                    <div class="column">
                        <div v-if="isInRole('motivo-alterar') || isInRole('motivo-consultar')" class="panel">
                            <p class="panel-heading background-1">
                               {{$t('MANUTENCAOLISTAR.MOTIVOS')}} 
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'motivos', query: { procurar: procurar.motivo }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.motivo"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'motivos' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}} 
                                </router-link>
                                <router-link v-if="isInRole('motivo-alterar')" :to="{ name: 'motivo' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}} 
                                </router-link>
                            </div>
                        </div>                        
                    </div>
                    <div class="column">
                        <div v-if="isInRole('liberacaoAutomatica-alterar') || isInRole('liberacaoAutomatica-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.LIBERACAOAUTO')}}
                            </p>                            
                            <form @submit.stop.prevent="$router.push({ name: 'liberacoesautomatica', query: { procurar: procurar.liberacaoAutomatica }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.liberacaoAutomatica"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'liberacoesautomatica' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('liberacaoAutomatica-alterar')" :to="{ name: 'liberacaoautomaticaconfig' }" class="button is-primary is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.CONFIGURAR')}}
                                </router-link>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-3" v-if="isInRole('reducaoacrescimo-alterar') || isInRole('reducaoacrescimo-consultar')">
                        <div class="panel">
                            <p class="panel-heading background-1">
                               {{$t('MANUTENCAOLISTAR.REDUCAOACRESCIMO')}} 
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'reducoesacrescimos', query: { procurar: procurar.reducaoacrescimo }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.reducaoacrescimo"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'reducoesacrescimos' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}} 
                                </router-link>
                                <router-link v-if="isInRole('reducaoacrescimo-alterar')" :to="{ name: 'reducaoacrescimo' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}} 
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="column is-3" v-if="isInRole('modelolaudosdescritivos-alterar') || isInRole('modelolaudosdescritivos-consultar')">
                        <div class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.LAUDODESCRITIVO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'modeloslaudodescritivo', query: { procurar: procurar.modeloLaudo }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.modeloLaudo"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'modeloslaudodescritivo' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('modelolaudosdescritivos-alterar')" :to="{ name: 'modelolaudodescritivo' }"  class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>                                                    

                </div>                
            </div>
        </b-collapse>
        <b-collapse class="card has-background-transparent"
                    animation="slide"
                    :open="isOpen == 3"
                    @open="isOpen = 3;salvarCollapseIndex()">
            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    {{$t('MANUTENCAOLISTAR.OUTROS')}}
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>
            </div>
            <div class="columns">
                <div class="column">
                    <div v-if="isInRole('unidade-alterar') || isInRole('unidade-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.UNIDADES')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'unidades', query: { procurar: procurar.unidade }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.unidade"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'unidades' }" class="button is-light is-fullwidth">
                               {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('unidade-alterar')" :to="{ name: 'unidade' }" class="button is-link is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('guiaLocal-alterar') || isInRole('guiaLocal-consultar')" class="panel">
                        <p class="panel-heading background-2">
                            {{$t('MANUTENCAOLISTAR.LOCAIS')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'locais', query: { procurar: procurar.guiaLocal }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.guiaLocal"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'locais' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('guiaLocal-alterar')" :to="{ name: 'local' }" class="button is-link is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('tipoDeLocal-alterar') || isInRole('tipoDeLocal-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.TIPOSLOCAIS')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'tiposdelocais', query: { procurar: procurar.tipoDeLocal }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.tipoDeLocal"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'tiposdelocais' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('tipoDeLocal-alterar')" :to="{ name: 'tipodelocal' }" class="button is-link is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                 <div class="column">
                        <div v-if="isInRole('tipos-agendamento-alterar') || isInRole('tipos-agendamento-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.TIPOAGENDAMENTO')}}
                            </p>                                                
                            <form @submit.stop.prevent="$router.push({ name: 'tiposdeagendamento', query: { procurar: procurar.tiposdeAgendamento }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.tiposdeAgendamento"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'tiposdeagendamento' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('tipos-agendamento-alterar')" :to="{ name: 'tipoagendamento' }" class="button is-link is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <div v-if="isInRole('pesquisa-alterar') || isInRole('pesquisa-consultar')" class="panel">
                        <p class="panel-heading background-2">
                            {{$t('MANUTENCAOLISTAR.PESQUISAS')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'pesquisas', query: { procurar: procurar.pesquisa }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.pesquisa"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'pesquisas' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('pesquisa-alterar')" :to="{ name: 'pesquisa' }" class="button is-link is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('feriado-alterar') || isInRole('feriado-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.FERIADO')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'feriados', query: { procurar: procurar.feriado }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.feriado"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'feriados' }" class="button is-light is-fullwidth">
                               {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('feriado-alterar')" :to="{ name: 'feriado' }" class="button is-link is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('condicaopagamento-alterar') || isInRole('condicaopagamento-consultar')" class="panel">
                        <p class="panel-heading background-2">
                            {{$t('MANUTENCAOLISTAR.CONDPAGAMENTO')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'condicoespagamentos', query: { procurar: procurar.condicaopagamento }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.condicaopagamento"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'condicoespagamentos' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('condicaopagamento-alterar')" :to="{ name: 'condicaopagamento' }" class="button is-link is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('especie-pagamento-alterar') || isInRole('especie-pagamento-consultar')" class="panel">
                        <p class="panel-heading background-1">
                           {{$t('MANUTENCAOLISTAR.ESPECIEPAGAMENTO')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'especiespagamentos', query: { procurar: procurar.especiepagamento }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.especiepagamento"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'especiespagamentos' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('especie-pagamento-alterar')" :to="{ name: 'especiepagamento' }" class="button is-link is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <div v-if="isInRole('sorotecageladeira-alterar') || isInRole('sorotecageladeira-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.SOROTECA')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'sorotecageladeiras', query: { procurar: procurar.sorotecageladeira }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.sorotecageladeira"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'sorotecageladeiras' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('sorotecageladeira-alterar')" :to="{ name: 'sorotecageladeira' }" class="button is-link is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('sorotecacontainer-alterar') || isInRole('sorotecacontainer-consultar')" class="panel">
                        <p class="panel-heading background-2">
                            {{$t('MANUTENCAOLISTAR.CONTAINERS')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'sorotecascontainers', query: { procurar: procurar.sorotecacontainer }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.sorotecacontainer"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'sorotecascontainers' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('sorotecacontainer-alterar')" :to="{ name: 'sorotecacontainer' }" class="button is-link is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('configTISS-alterar') || isInRole('configTISS-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.CONFIGTISS')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'configuracoestiss', query: { procurar: procurar.configTISS }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.configTISS"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'configuracoestiss' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('configTISS-alterar')" :to="{ name: 'configuracaotiss' }" class="button is-link is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('cron-alterar')" class="panel">
                        <p class="panel-heading background-2">
                            {{$t('MANUTENCAOLISTAR.CONFIGAGENDAMENTO')}}
                        </p>
                        <p class="panel-block">
                            <small>{{$t('MANUTENCAOLISTAR.SERVICOPERIODO')}}</small>                            
                        </p>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'agendamentotarefa' }" class="button is-light is-fullwidth">
                               {{$t('MANUTENCAOLISTAR.ALTERAR')}} 
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

        </b-collapse>
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                isOpen: localStorage.manutencaoCollapseIndex ?? 0,
                procurar: {}
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config',
                'integradores'
            ])
        },
        methods: {
            salvarCollapseIndex() {
                localStorage.manutencaoCollapseIndex = this.isOpen;
            },
        }
    }
</script>