<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{$t('ALTERARDATAULTIMAREFEICAO.ALTERAR')}}</p>
        </header>
        <section class="modal-card-body">
            <b-field>
                <b-datetimepicker rounded
                                  :placeholder="$t('ALTERARDATAULTIMAREFEICAO.DATAHORA')"
                                  icon="calendar-today"
                                  v-model="novaDataDaUltimaRefeicao"
                                  inline
                                  horizontal-time-picker>
                </b-datetimepicker>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                      @click="alterarDataUltimaRefeicao">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close')">{{$t('ALTERARDATAULTIMAREFEICAO.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                novaDataDaUltimaRefeicao: new Date()
            }
        },
        methods: {
            secondsToHMS(secs) {
                function z(n){return (n<10?'0':'') + n;}
                const sign = secs < 0? '-':'';
                secs = Math.abs(secs);
                return sign + z(secs/3600 |0) + ':' + z((secs%3600) / 60 |0);
            },            
            alterarDataUltimaRefeicao() {
               const diffSeconds = (new Date() - new Date(this.novaDataDaUltimaRefeicao))/1000 ;

                const horas = this.secondsToHMS(diffSeconds);
                
                console.log(`${horas} horas de jejum!`);

                this.$emit('setarHorasJejum', horas)
                this.$emit('close')
            }
        }
    }
</script>