<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns box">
            <div class="column is-5">
                <b-field>
                    <b-input :placeholder="$t('GUIALIST.BUSCARGUIAS')"
                             v-model="procurar"
                             icon="magnify"
                             @input="loadAsyncData"></b-input>
                </b-field>
            </div>
            <div class="column">
                <b-field>
                    <b-input :placeholder="$t('GUIALIST.REFERENCIA')"
                            v-model="referencia"
                            icon="magnify"
                            @input="loadAsyncData"></b-input>
                </b-field>   
            </div>
            <div class="column is-2">
                <form>
                    <b-field>
                        <b-input :placeholder="$t('GUIALIST.CODIGO')"
                                v-model="id"
                                type="number"
                                icon-right="edit"
                                icon-right-clickable
                                >
                        </b-input>
                    </b-field>
                </form>
            </div>
        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="onPageChange"
                 :aria-next-label="$t('GUIALIST.PROXPAGINA')"
                 :aria-previous-label="$t('GUIALIST.PAGINAANTERIOR')"
                 :aria-page-label="$t('GUIALIST.PAGINA')"
                 :aria-current-label="$t('GUIALIST.PAGINAATUAL')"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"
                 @sort="onSort">

            <template @click="abrirCadastro(props.row.id)">
                <b-table-column  v-slot="props" field="Id" :label="$t('GUIALIST.CODIGO')" sortable >
                    {{ props.row.id }}
                    <br><small title="Referência">{{ props.row.referencia }}</small>
                </b-table-column>

                <b-table-column  v-slot="props" field="PacienteNome" :label="$t('SISTEMA.PACIENTE')" sortable >
                    {{ props.row.pacienteNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="ConvenioNome" :label="$t('SISTEMA.CONVENIO')" sortable >
                    {{ props.row.convenioNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="MedicoNome" :label="$t('SISTEMA.SOLICITANTE')" sortable >
                    {{ props.row.medicoNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="LocalDeOrigemNome" :label="$t('GUIALIST.LOCALORIGEM')" sortable >
                    {{ props.row.localDeOrigemNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="DataDeEmissao" :label="$t('GUIALIST.EMISSAO')" sortable >
                    {{ $moment(props.row.dataDeEmissao).format("DD/MM/YYYY HH:mm") }}
                </b-table-column>

                <b-table-column  v-slot="props" custom-key="actions" numeric >
                    <router-link :to="{ name: 'guia', params: { id: props.row.id }}" class="button is-small is-primary" title="Editar">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import debounce from 'lodash/debounce'
    import { mapState, mapGetters } from 'vuex'

    export default {
        data(){
            return {
                model: [],
                total: 0,
                loading: false,
                pacienteId: this.$route.query.pacienteId,
                medicoId: this.$route.query.medicoId,
                guiaId: this.$route.query.guiaId,
                convenioId: this.$route.query.convenioId,
                procurar: this.$route.query.procurar ?? '',
                referencia: this.$route.params.referencia ?? '',
                amostra: this.$route.params.amostra ?? '',
                ordenarPor: 'DataUltimaModificacao',
                ordenar: 'desc',
                defaultOrdenar: 'desc',
                page: 1,
                perPage: 20,
                selecionado: null,
                id: null
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('GUIALIST.ATENDIMENTO'),
                    this.$t('GUIALIST.GUIA'),
                ]
            },
            ...mapState([
                'unidadeId'
            ])
        },
        methods: {
            loadAsyncData: debounce(function () {
                const params = [
                    `nome=${this.procurar}`,
                    `referencia=${this.referencia}`,
                    `unidadeId=${this.unidadeId}`,
                    `amostra=${this.amostra}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`
                ];

                if (this.pacienteId != null) 
                    params.push(`pacienteId=${this.pacienteId}`);
                if (this.medicoId != null) 
                    params.push(`medicoId=${this.medicoId}`);
                if (this.convenioId != null) 
                    params.push(`convenioId=${this.convenioId}`);

                this.loading = true
                this.$http.get(`/api/atendimento/procurar?${params.join('&')}`)
                    .then(({ data }) => {
                        this.model = data;
                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.loadAsyncData()
                        }
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                        this.$router.replace({query: {procurar: this.procurar, page: this.page }})
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            }, 500),
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
            abrirCadastro() {
                this.$router.push({ path: `/atendimento/guia/${this.selecionado.id}` })
            }
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>