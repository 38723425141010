<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button type="button" class="delete"></button>
                    {{$t('TABPRECOCADASTRO.SALVARTAB')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('TABPRECOCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="100" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-third">
                                    <ValidationProvider rules="required" name="model.tipo" v-slot="{ errors, valid }">
                                        <b-field :label="$t('TABPRECOCADASTRO.TIPO')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-input v-if="model.id" type="text" v-model="model.tipo" readonly></b-input>
                                            <b-select v-else :placeholder="$t('TABPRECOCADASTRO.SELTIPO')" class="is-fullwidth" v-model="model.tipo">
                                                <option value="Moeda">Moeda</option>
                                                <option value="AMB">AMB</option>
                                                <option value="CBHPM">CBHPM</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <historico-alteracoes :tabela="'TabelaPreco'" :chave="model.id"></historico-alteracoes>
                        </article>
                    </div>
                </div>
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column is-5">
                            <b-field expanded class="search-area">
                                <b-input :placeholder="$t('TABPRECOCADASTRO.PROCURAPOR') +colunaSelecionada"
                                        v-model="procurar"
                                        icon="magnify"
                                        expanded
                                        icon-clickable
                                        @icon-click="loadData"
                                        @input="loadData"></b-input>
                                <b-dropdown aria-role="list" v-model="colunaSelecionada">
                                    <button type="button" class="button is-primary dropdown-opcoes" slot="trigger" slot-scope="{ active }">
                                        <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                    </button>

                                    <b-dropdown-item aria-role="listitem" value="Apelido">{{$t('TABPRECOCADASTRO.APELIDO')}}</b-dropdown-item>
                                    <b-dropdown-item aria-role="listitem" value="Nome">{{$t('TABPRECOCADASTRO.NOME')}}</b-dropdown-item>
                                    <hr />
                                    <b-dropdown-item aria-role="listitem" :value="coluna" v-for="coluna in colunas" :key="coluna.id">{{coluna.nome}}</b-dropdown-item>
                                </b-dropdown>                             
                            </b-field>
                        </div>
                        <div class="column is-2">
                                <b-select :placeholder="$t('TABPRECOCADASTRO.CODINTEG')" v-model="colunaDinamica" @input="loadData">                                    
                                    <option
                                        v-for="option in colunas"
                                        :value="option.nome"
                                        :key="option.id">
                                        {{ option.nome }}
                                    </option>
                                </b-select>
                                                                
                        </div>                    

                    </div>
                    <div class="columns">
                        <div class="column is-flex ">                          
                            <b-button icon-left="percent" @click="alterarPercentual = !alterarPercentual">
                                {{$t('TABPRECOCADASTRO.REAJUSTEVAL')}}
                            </b-button>
                            <b-switch @input="bloquearExames" v-model="bloquear">
                                {{$t('TABPRECOCADASTRO.BLOQEXA')}}
                            </b-switch>                               
                            <b-switch @input="loadData" v-model="exibirBloqueados">
                                {{$t('TABPRECOCADASTRO.APENASBLOQ')}}
                            </b-switch> 
                             <b-switch @input="desativarTabela" v-model="desativar">
                                {{$t('TABPRECOCADASTRO.DESATIVAR')}}
                            </b-switch>                                 
                        </div>                        
                    </div>

                    <article v-if="alterarPercentual" class="notification">
                        <div class="columns">
                            <div class="column">
                                <b-field :label="$t('TABPRECOCADASTRO.PERCENTUAL')">
                                    <b-input type="number" step="0.01" class="column is-one-third" v-model="percentual"></b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <div class="buttons">
                                    <b-button icon-left="check" type="is-success" @click="alterarExames">{{$t('TABPRECOCADASTRO.SIM')}}</b-button>
                                    <b-button icon-left="close" type="is-danger" @click="alterarPercentual = !alterarPercentual">{{$t('TABPRECOCADASTRO.NAO')}}</b-button>
                                </div>
                            </div>
                        </div>
                    </article>


                    <div class="columns">
                        <div class="column is-12">
                            <b-table v-if="lista.length != 0" :data="lista"
                                     :loading="loading"                                     
                                     paginated
                                     striped
                                     hoverable
                                     :total="total"
                                     @page-change="onPageChange"
                                     :aria-next-label="$t('TABPRECOCADASTRO.PROXPAGINA')"
                                     :aria-previous-label="$t('TABPRECOCADASTRO.PAGANTERIOR')"
                                     :aria-page-label="$t('TABPRECOCADASTRO.PAGINA')"
                                     :aria-current-label="$t('TABPRECOCADASTRO.PAGATUAL')"
                                     :default-sort-direction="defaultOrdenar"
                                     :default-sort="[ordenarPor, ordenar]"
                                     sort-icon-size="is-small"
                                     @sort="onSort">

                                <template >
                                    <b-table-column  v-slot="props" field="Apelido" :label="$t('TABPRECOCADASTRO.APELIDO')">
                                        {{ props.row.exameApelido }}
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="Nome" :label="$t('TABPRECOCADASTRO.NOME')">
                                        {{ props.row.exameNome }}
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="Código de Integração" :label="$t('TABPRECOCADASTRO.CODINTEG')" >
                                        {{ props.row.exameCodigoFiltro }}                                        
                                    </b-table-column>                         

                                    <b-table-column  v-slot="props" v-if="model.tipo == 'Moeda'" field="Valor" :label="$t('TABPRECOCADASTRO.VAL')">
                                        <input type="number" step=".00000001" class="input" v-model="props.row.valor" name="Preco" />
                                    </b-table-column>

                                    <b-table-column  v-slot="props" v-if="model.tipo == 'AMB'" field="Valor" :label="$t('TABPRECOCADASTRO.QTDEUS')">
                                        <input type="number" step=".00000001" class="input" v-model="props.row.valor" name="Preco" />
                                    </b-table-column>

                                    <b-table-column  v-slot="props" v-if="model.tipo == 'AMB' || model.tipo == 'CBHPM'" field="Qtde. Filme" :label="$t('TABPRECOCADASTRO.FILME')">
                                        <input type="number" class="input" v-model="props.row.filme" name="Filme" />
                                    </b-table-column>

                                    <b-table-column  v-slot="props" v-if="model.tipo == 'CBHPM'" field="PercentualPorte" :label="$t('TABPRECOCADASTRO.PERCENTUALPORTE')">
                                        <input type="text" class="input" v-model="props.row.percentualPorte" name="PercentualPorte" />
                                    </b-table-column>

                                    <b-table-column  v-slot="props" v-if="model.tipo == 'CBHPM' || model.tipo == 'AMB'" field="CustoOperacional" :label="$t('TABPRECOCADASTRO.CUSTOOP')">
                                        <input type="number" step=".00000001" class="input" v-model="props.row.custoOperacional" name="CustoOperacional" />
                                    </b-table-column>

                                    <b-table-column  v-slot="props" v-if="model.tipo == 'CBHPM'" field="ValorCBHPM" :label="$t('TABPRECOCADASTRO.VALCBHPM')">
                                        <b-select class="is-fullwidth" v-model="props.row.ValorCBHPM.Id">
                                            <option v-for="valorCBHPM in model.valoresCBHPM"
                                                    :key="valorCBHPM"
                                                    :value="valorCBHPM">
                                                {{ valorCBHPM }}
                                            </option>
                                        </b-select>
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="Bloqueado" :label="$t('TABPRECOCADASTRO.BLOQ')" centered>
                                        <b-checkbox class="level-item" v-model="props.row.bloqueado">
                                        </b-checkbox>
                                        <b-field>
                                            <b-input v-if="props.row.bloqueado" :placeholder="$t('TABPRECOCADASTRO.MOTIVOBLOQ')"
                                                     v-model="props.row.motivoBloqueio"></b-input>
                                        </b-field>
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="RequerAutorizacao" :label="$t('TABPRECOCADASTRO.AUT')" centered>
                                        <b-checkbox v-model="props.row.requerAutorizacao">
                                        </b-checkbox>
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="ValorNegociado" :label="$t('TABPRECOCADASTRO.NEGOCIADO')" centered>
                                        <b-checkbox v-model="props.row.valorNegociado">
                                        </b-checkbox>
                                    </b-table-column>
                                </template>

                                <template slot-scope="props" slot="header">
                                    <span v-if="props.column.label != 'adicional'">{{props.column.label}}</span>
                                </template>
                            </b-table>
                        </div>

                    </div>
                </article>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      v-show="isInRole('tabelapreço-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('TABPRECOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('TABPRECOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapMutations, mapState, mapGetters } from 'vuex'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar,
            historicoAlteracoes
        },
        data() {
            return {
                model: {
                    id: null,
                    tipo: null,
                    nome: null,
                    valoresCBHPM: [],
                    exames: [],
                    desativado: null,
                },
                lista: [],
                listaCompleta: [],
                total: 0,
                loading: true,
                procurar: null,
                ordenarPor: 'Apelido',
                ordenar: 'asc',
                defaultOrdenar: 'asc',
                page: 1,
                perPage: 9999,
                alterarPercentual: false,
                percentual: 0,
                bloquear: false,
                salvandoDados: false,
                exibirBloqueados: false,
                colunas: [],
                colunaSelecionada: 'Apelido',
                colunaDinamica: null,
                desativar: false,
            }
        },
        created() {
            this.$http.get('/api/manutencao/tabelapreco?id=' + this.$route.params.id)
                .then(res => {
                    this.model = res.data;
                    if(this.model.desativado) {
                        this.desativar = true;
                    }
                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('TABPRECOCADASTRO.MANUTENCAO'),
                    this.$t('TABPRECOCADASTRO.TABPRECO'),
                    this.model.id ?? 'novo'
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            modalHistoricoIsOpened: {
                get() {
                    return this.$store.state.componentes.modalHistoricoIsOpened;
                },
                set() {
                    return this.$store.commit('componentes/TOGGLE_MODAL_HISTORICO')
                }
            },
        },
        methods: {
            async loadData() {

                if(this.listaCompleta.length > 0) {
                    this.model.exames = this.listaCompleta;
                }

                let examesFiltrados = this.model.exames;
                
                if(this.procurar && this.procurar.length>=3){
                    if(this.colunaSelecionada == 'Apelido'){                        
                        examesFiltrados = examesFiltrados.filter(item => item.exameApelido.toUpperCase().indexOf(this.procurar.toUpperCase()) != -1);
                        this.carregaCodigosExames(examesFiltrados);
                    }
                    else if(this.colunaSelecionada == 'Nome'){                        
                        examesFiltrados = examesFiltrados.filter(item => item.exameNome.toUpperCase().indexOf(this.procurar.toUpperCase()) != -1);
                        this.carregaCodigosExames(examesFiltrados);
                    }
                    else {           
                        if(this.listaCompleta.length == 0) {
                            this.listaCompleta = this.model.exames;
                        }

                        this.loadAsyncData();   
                        examesFiltrados = [];
                    }
                }else{

                    this.carregaCodigosExames(examesFiltrados);
                
                }          
                                                      
                if(this.exibirBloqueados){
                    
                    examesFiltrados = examesFiltrados.filter(item => item.bloqueado);
                }

                                
                this.lista = examesFiltrados;                                                                
                this.$forceUpdate();
                              
                                                
            },    
            async carregaCodigosExames(examesFiltrados){
                
                    if(this.colunaDinamica){   
                        if(!this.procurar){
                            const startIndex = ( (this.page -1) * 20); 
                            const endIndex = (this.page * 20) - 1; 
                                                                    
                            for(let i = startIndex; i < endIndex; i++){                                                                                            
                                const promises = [this.$http.get('/api/manutencao/TabelaPrecoExamesCodigos?exameId=' + examesFiltrados[i].exameId)];
                                const [retorno] = await Promise.all(promises);
                                examesFiltrados[i].exameCodigoFiltro = retorno.data.lista?.find(c => c.exameCodigoTipoNome === this.colunaDinamica)?.codigo;                    
                            }  
                        }else{                                                                    
                            for(let i = 0; i < examesFiltrados.length; i++){                                                                                            
                                const promises = [this.$http.get('/api/manutencao/TabelaPrecoExamesCodigos?exameId=' + examesFiltrados[i].exameId)];
                                const [retorno] = await Promise.all(promises);
                                examesFiltrados[i].exameCodigoFiltro = retorno.data.lista?.find(c => c.exameCodigoTipoNome === this.colunaDinamica)?.codigo;                    
                            }                             
                        }   
                  
                    }
            },               
            loadAsyncData() {

                let tipoCodigo = 0;
                let fieldName = this.colunaSelecionada;
                const isObject = (obj) => {
                    const type = typeof obj;
                    return type === 'function' || type === 'object' && !!obj;
                };
                if (this.colunaSelecionada && isObject(this.colunaSelecionada)){
                    tipoCodigo = this.colunaSelecionada.id;
                    fieldName = `Codigo_${tipoCodigo}`;                    
                }

                const params = [
                    `id=${this.$route.params.id}`,
                    `procurar=${this.procurar}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`,
                    `fieldName=${fieldName}`,
                    `perPage=${this.perPage}`
                ].join('&')

                this.loading = true
                this.$http.get(`/api/manutencao/tabelaprecoexames?${params}`)
                    .then(({ data }) => {
                        data.lista = data.lista?.map(p => {
                            p.valor = parseFloat(p.valor).toFixed(2);                                                         
                            p.ValorCBHPM = {Id: p.cbhpmId, Valor: p.cbhpmValor};
                            p.exameCodigoFiltro= '';
                            return p;
                        })

                        this.lista = data.lista
                        this.model.exames = data.lista
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                                                
                        if(this.lista.filter(item => !item.bloqueado ) == 0 )
                        {
                            this.bloquear = true;
                        }

                        this.colunas = data.codigos?.map(c => {
                            return {
                                ...c,
                                toString: () => {
                                    return c.nome;
                                }
                            }
                        });
                        
                    })
                    .catch((error) => {
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            },

            onSubmit() {
                this.salvandoDados = true;

                if(this.lista.filter(item => !item.bloqueado ).length > 0)
                {
                    this.desativar = false;
                    this.model.desativado = false;
                }
                
                this.$http.post(`/api/manutencao/tabelapreco`, this.model)
                    .then(() => {
                        this.salvandoDados = false
                        this.$router.push({ name: 'tabelasprecos' })
                    })
                    .catch((error) => {
                        this.salvandoDados = false
                        throw error
                    });
            },
            onPageChange(page) {
                this.page = page;                
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
            },
            bloquearExames() {
                if(!this.bloquear && this.desativar) {
                    this.model.desativado = false;
                    this.desativar = false;
                }

                for (const i in this.model.exames) {
                    this.model.exames[i].bloqueado = this.bloquear;
                }
            },
            desativarTabela(){
                if(this.desativar) {
                    this.bloquear = true;
                    this.model.desativado = true;
                    this.bloquearExames()
                }else { 
                    this.model.desativado = false;
                }
            },
            alterarExames() {
                this.alterarPercentual = !this.alterarPercentual;

                for (const i in this.model.exames) {
                    if (this.model.exames[i].valor != null) {
                        this.model.exames[i].valor = (parseFloat(this.model.exames[i].valor) + (parseFloat(this.model.exames[i].valor) * (parseFloat(this.percentual) / 100))).toFixed(2);
                    }
                }
            },
            ...mapMutations('componentes', [
                'TOGGLE_MODAL_HISTORICO'
            ])
        },
        mounted() {
            this.loadAsyncData()
        }
    }
</script>