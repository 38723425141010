import { render, staticRenderFns } from "./soroteca-armazenar.vue?vue&type=template&id=5f2b1d13&scoped=true&"
import script from "./soroteca-armazenar.vue?vue&type=script&lang=js&"
export * from "./soroteca-armazenar.vue?vue&type=script&lang=js&"
import style0 from "./soroteca-armazenar.vue?vue&type=style&index=0&id=5f2b1d13&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f2b1d13",
  null
  
)

export default component.exports