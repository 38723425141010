<template>
    <section>
        <div v-if="isLoading">
            <b-loading active></b-loading>
        </div>
        <div v-else>
            <div v-if="!isUpdated" class="has-background-danger has-text-white is-justified-between" style="padding: 10px">
                <span>
                    Sistema desatualizado. Por favor, atualize antes de continuar.
                </span>
                <b-button size="is-small is-pulled-right" @click="refresh">Atualizar</b-button>
            </div>
            <div v-if="!isAuthenticated">
                <login></login>
            </div>
            <div v-else>
                <section v-if="isPainel">
                    <router-view></router-view>
                </section>
                <section v-else-if="isPortal">
                    <navbar-portal></navbar-portal>
                    <div id="body">
                        <div class="columns">
                            <div class="column">
                                <navbar-portal-convenio v-if="isPortalConvenio"></navbar-portal-convenio>
                                <transition name="component-fade"
                                            mode="out-in">
                                    <router-view></router-view>
                                </transition>
                            </div>
                        </div>
                    </div>
                </section>
                <section v-else>
                    <navbar-principal></navbar-principal>
                    <div id="body">
                        <div class="columns">
                            <sidebar></sidebar>
                            <div :class="(sidebarIsOpened) ? 'column is-10' : 'column is-12'">
                                <b-message v-for="(erro, index) in erros" v-bind:key="index" title="Erro" type="is-danger" aria-close-label="Fechar mensagem">
                                    {{ erro }}
                                </b-message>
                                <transition name="component-fade"
                                            mode="out-in">
                                    <router-view></router-view>
                                </transition>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
</template>

<style>
@import '/Home/CustomizacaoCss';
</style>

<script>
    import login from '@/components/login.vue'
    import navbarPrincipal from '@/components/navbarPrincipal.vue'
    import sidebar from '@/components/sidebar.vue'
    import navbarPortal from '@/components/portal/navbarPortal.vue'
    import navbarPortalConvenio from '@/components/portal/navbarPortalConvenio.vue'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'App',
        components: {
            login,
            navbarPrincipal,
            navbarPortal,
            sidebar,
            navbarPortalConvenio
        },
        computed: {
            ...mapState([
                'config',
                'erros',
                'isLoading',
                'isAuthenticated',
                'isUpdated',
                'usuario'
            ]),
            ...mapState('componentes', [
                'sidebarIsOpened'
            ]),            
            isPainel() {
                return this.$route.name == 'paineldeatendimento' || this.$route.name == 'totendeatendimento'
            },
            isPortal() {
                return this.$route.path.startsWith('/portal/')
            },
            isPortalConvenio(){
                return this.usuario.tipos[0] == 'Convenio'
            }
        },
        created() {
            setTimeout(() => {this.checkUser()},500);
            window.addEventListener('resize', this.handleResize)
            document.addEventListener('serviceWorkerUpdated', this.needUpdate)
            this.handleResize()
            this.$moment.locale('pt-br')            
            
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        methods: {
            checkUser(){                           
                if( this.usuario.nome){
                    this.$http.post('/api/auth/checkUser', {  UserName: this.usuario.nome})
                        .then(res => res.data)
                        .then(data => {                                
                            if(!data)                            
                                this.$router.go(0);                                   
                        })                      
                }
                                        
            },            
            handleResize() {
                this.$store.commit('componentes/HANDLE_RESIZE')
            },
            needUpdate() {
                this.$store.commit('SET_ISUPDATED', false)
            },
            toggleSidebar() {
                this.$store.commit('componentes/SET_SIDEBAR', this.$store.state.componentes.sidebarIsOpened)
            },
            ...mapActions([
                'logout'
            ]),
            refresh() {
                setTimeout(() => {
                    window.location.reload()
                })
            }
        }
    }
</script>
