<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    {{$t('UNIDADECADASTRO.SALVANDOUNIDADE')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('UNIDADECADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="30" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-third">
                                    <ValidationProvider name="model.horaRetornoPadrao" v-slot="{ errors, valid }">
                                        <b-field :label="$t('UNIDADECADASTRO.HORAPADRAO')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-timepicker editable icon="clock" v-model="horaRetornoPadrao"></b-timepicker>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-third">
                                    <ValidationProvider name="model.horaFimExpediente" v-slot="{ errors, valid }">
                                        <b-field :label="$t('UNIDADECADASTRO.FIMEXP')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-timepicker editable icon="clock" v-model="horaFimExpediente"></b-timepicker>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-third">
                                    <ValidationProvider name="model.modeloResultado" v-slot="{ errors, valid }">
                                        <b-field :label="$t('UNIDADECADASTRO.MODELORES')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-select :placeholder="$t('UNIDADECADASTRO.MODELOSEL')" class="is-fullwidth" v-model="model.modeloResultado">
                                                <option v-for="(item, index) in model.modelos" v-bind:key="index" :value="item">{{item}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>
                    </div> 
                    
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <article class="panel">
                                <div class="panel-heading">                                      
                                    <b-field>
                                        <b-switch v-model="model.desativado" type="is-danger">
                                            {{$t('UNIDADECADASTRO.DESATIVADO')}}
                                        </b-switch>
                                    </b-field>                             
                                </div>
                            </article>       
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('UNIDADECADASTRO.CODINTEG')}}
                                </div>
                                <div class="panel-block">
                                    <table class="table is-bordered is-striped is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th>{{$t('UNIDADECADASTRO.TIPO')}}</th>
                                                <th>{{$t('UNIDADECADASTRO.CODIGO')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in model.codigosIntegrador" v-bind:key="index">
                                                <td>
                                                    <b-select :placeholder="$t('UNIDADECADASTRO.INTEG')" v-model="item.integradorId">
                                                        <option v-for="option in integradores"
                                                                :value="option.id"
                                                                :key="option.id">
                                                            {{ option.nome }}
                                                        </option>
                                                    </b-select>
                                                </td>
                                                <td>
                                                    <input class="input horario " type="text" v-model="item.codigo">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="panel-block">
                                    <button type="button" class="button is-info is-fullwidth" @click="addCodigo">
                                        {{$t('UNIDADECADASTRO.ADDCODIGO')}}
                                    </button>
                                </div>
                            </article>

                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('UNIDADECADASTRO.CONFIGNF')}}
                                </div>
                                <div class="panel-block">
                                    <table class="table is-bordered is-striped is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th>{{$t('UNIDADECADASTRO.CAMPOSNF')}}</th>
                                                <th>{{$t('UNIDADECADASTRO.VALORESNF')}}</th>
                                                <th>customizado</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in model.configuracoesNf" v-bind:key="index">
                                                <td>
                                                    <input class="input" type="text" v-model="item.campo">
                                                </td>
                                                <td>
                                                    <input class="input" type="text" v-model="item.valor">
                                                </td>
                                                <td>
                                                    <b-checkbox class="input"  v-model="item.customizado"></b-checkbox>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="panel-block">
                                    <button type="button" class="button is-info is-fullwidth" @click="addConfigNf">
                                        {{$t('UNIDADECADASTRO.ADDCONFIG')}}
                                    </button>
                                </div>
                            </article>
                        </article>                        
                    </div>                  
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success" v-show="isInRole('unidade-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('UNIDADECADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('UNIDADECADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    horaRetornoPadrao: '',
                    horaFimExpediente: '', 
                    modeloResultado: null,   
                    codigosIntegrador: [],
                    configuracoesNf: [],
                    modelos: [],
                    desativado: false,
                },
                salvandoDados: false,
                horaRetornoPadrao: null,
                horaFimExpediente: null
            }
        },
        created() {
            this.$http.get('/api/manutencao/unidade?id=' + this.$route.params.id)
                .then(res => {
                    this.model = res.data;

                    if(this.model.horaRetornoPadrao){
                        this.horaRetornoPadrao = new Date();                    
                        this.horaRetornoPadrao.setHours(this.model.horaRetornoPadrao.split(":")[0]);
                        this.horaRetornoPadrao.setMinutes(this.model.horaRetornoPadrao.split(":")[1]);
                    }

                    if(this.model.horaFimExpediente){
                        this.horaFimExpediente = new Date();
                        this.horaFimExpediente.setHours(this.model.horaFimExpediente.split(":")[0]);
                        this.horaFimExpediente.setMinutes(this.model.horaFimExpediente.split(":")[1]);
                    }
                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('UNIDADECADASTRO.MANUTENCAO'),
                    this.$t('UNIDADECADASTRO.UNIDADE'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                if(this.horaRetornoPadrao != null){
                    this.model.horaRetornoPadrao = ("" + this.horaRetornoPadrao.getHours()).padStart(2, '0') + ":" + ("" + this.horaRetornoPadrao.getMinutes()).padStart(2, '0');
                }

                if(this.horaFimExpediente != null){
                    this.model.horaFimExpediente = ("" + this.horaFimExpediente.getHours()).padStart(2, '0') + ":" + ("" + this.horaFimExpediente.getMinutes()).padStart(2, '0');
                }

                this.salvandoDados = true;
                this.$http.post(`/api/manutencao/unidade`, this.model)
                    .then(() => {
                        this.salvandoDados = false
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false
                        throw error
                    });
            },
            addCodigo() {
                this.model.codigosIntegrador.push({
                    integradorId: null,
                    codigo: null
                });
            },
            addConfigNf() {
                this.model.configuracoesNf.push({
                    id: 0,
                    campo: null,
                    valor: null,
                    unidade: {
                        id: this.$route.params.id
                    },
                    customizado: true,
                });
            }
        }
    }
</script>